import {DurationBinomeEnum} from '../enums/duration-binome.enum';
import {TypeContactBinomeEnum} from '../enums/type-contact-binome.enum';
import {User} from './user.model';

export class RelationBinome {
  id: number;
  idYoung: number;
  idIntervenant: number;
  typeBinome: number;
  type: TypeContactBinomeEnum;
  dureeBinome: DurationBinomeEnum;
  dateSignatureMoralEngagementContract?: Date;
  dateSignatureSponsorshipActionPlan?: Date;
  suivi: string;
  dateBeginBinome: string;
  dateEndBinome: string;
  commentaryEndBinome: string;

  user: User;
  relative: User;
}
