import {NgModule} from '@angular/core';
import {FocusMonitor} from '@angular/cdk/a11y';
import {ByteFormatPipe} from './pipe/byte-format.pipe';
import {MatFileInputComponent} from './mat-file-input.component';

@NgModule({
  imports: [MatFileInputComponent, ByteFormatPipe],
  providers: [FocusMonitor],
  exports: [MatFileInputComponent, ByteFormatPipe]
})
export class MatFileInputModule {}
