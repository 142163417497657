import {Injectable} from '@angular/core';
import {Alert} from '../model/alert.model';
import {AlertTypeEnum} from '../model/enums/alert-type.enum';
import {Observable, Subject} from 'rxjs';
import {NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Destroyed} from '../components/shared/directives/destroyed.directive';

@Injectable({
  providedIn: 'root'
})
export class AlertService extends Destroyed {
  private readonly subject = new Subject<Alert>();
  private keepAfterRouteChange = false;

  constructor(private readonly router: Router) {
    super();
    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
    this.router.events.pipe(this.untilDestroyed()).subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          // only keep for a single route change
          this.keepAfterRouteChange = false;
        } else {
          // clear alert messages
          this.clear();
        }
      }
    });
  }

  // enable subscribing to alerts observable
  onAlert(alertId?: string): Observable<Alert> {
    return this.subject.asObservable().pipe(filter((x) => x && x.alertId === alertId));
  }

  // convenience methods
  success(message: string, alertId?: string) {
    this.alert(new Alert({message, type: AlertTypeEnum.SUCCESS, alertId}));
  }

  error(message: string, alertId?: string) {
    this.alert(new Alert({message, type: AlertTypeEnum.ERROR, alertId}));
  }

  info(message: string, alertId?: string) {
    this.alert(new Alert({message, type: AlertTypeEnum.INFO, alertId}));
  }

  warn(message: string, alertId?: string) {
    this.alert(new Alert({message, type: AlertTypeEnum.WARNING, alertId}));
  }

  // main alert method
  alert(alert: Alert) {
    this.keepAfterRouteChange = alert.keepAfterRouteChange;
    this.subject.next(alert);
  }

  // clear alerts
  clear(alertId?: string) {
    this.subject.next(new Alert({alertId}));
  }
}
