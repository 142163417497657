<div *ngIf="group" [formGroup]="group" class="form-group">
  <label *ngIf="textLabel" [for]="fcName+i" [ngClass]="{'star': required}" class="label-info">{{textLabel}} </label>

  <input [formControlName]="fcName" [id]="fcName + i"
         [name]="fcName + i"
         [ngClass]="{'is-invalid': isInvalid() }"
         [placeholder]="placeholder"
         class="form-control"
         type="search">

  <div *ngIf="control && control.invalid && (errorMsg || errorMsg2 ) && submitted" class="invalid-feedback">
    <div *ngIf="control.errors.required else otherError">{{errorMsg}}</div>
    <ng-template #otherError>{{errorMsg2}}</ng-template>
  </div>
</div>

<div *ngIf="!group" class="form-group">
  <label *ngIf="textLabel" [for]="fcName+i" [ngClass]="{'star': required}" class="label-info">{{textLabel}}</label>
  <input [id]="fcName + i" [name]="fcName + i"
         [placeholder]="placeholder"
         [ngModel]="modelAttribute"
         (ngModelChange)="change($event)"
         class="form-control"
         type="search">
</div>
