import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormGroup} from '@angular/forms';
import {NgClass, NgIf} from '@angular/common';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule, NgClass]
})
export class InputTextComponent implements OnInit {
  @ViewChild('adresse10', {static: false}) adresse10: ElementRef<HTMLInputElement>;

  @Input() submitted: boolean; // Si le form a été soumis au moins une fois
  @Input() control: AbstractControl<unknown>; // FormControl
  @Input() fcName: string; // Nom du FormControl
  @Input() textLabel: string; // Label
  @Input() required = false; // Si le form est requis ou non
  @Input() errorMsg: string; // Message si l'input est required
  @Input() errorMsg2 = ''; // Message si l'input est faux
  @Input() group: UntypedFormGroup; // Group contenant le FormControle
  @Input() i = 0; // i si l'input est dans une boucle (pour éviter d'avoir 2 composants ayant le même id
  @Input() placeholder: string;

  @Input() modelAttribute: string; // Valeur entrée
  @Output() modelAttributeChange = new EventEmitter(); // Valeur envoyée au parent

  //autocomplete
  city: string = '';
  streetNumber: string = '';
  zip: string = '';
  address: string = '';

  change(newValue: string) {
    this.modelAttribute = newValue;
    this.modelAttributeChange.emit(newValue);
  }

  ngOnInit() {
    if (!this.placeholder) {
      this.placeholder = '';
    }
  }

  isInvalid() {
    if (this.required) {
      return (
        this.control &&
        (this.control.dirty || this.control.touched || this.submitted) &&
        this.control.invalid
      );
    } else {
      return this.control && (this.control.dirty || this.control.touched) && this.control.invalid;
    }
  }
}
