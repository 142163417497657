<div *ngIf="group && !hasCompare" [formGroup]="group" class="d-flex flex-column">
  <label *ngIf="textLabel" [for]="fcName+i" [ngClass]="{'star': required}" class="label-info">{{textLabel}} </label>

  <select [disabled]="disabled"
          [formControlName]="fcName"
          [id]="fcName + i"
          [name]="fcName + i"
          [ngClass]="{'is-invalid': error, 'disabled': group.get(fcName).disabled  }"
          class="form-control">
    <option *ngIf="!items || items.length === 0" value="">Aucun élément</option>
    <option *ngFor="let item of (disabled ? [] : items)" [ngValue]="item.id">{{item.name}}</option>
  </select>
  <div *ngIf="error" class="invalid-submit">
    <div>{{errorMsg}}</div>
  </div>

</div>

<div *ngIf="group && hasCompare" [formGroup]="group" class="d-flex flex-column">
  <label *ngIf="textLabel" [for]="fcName+i" [ngClass]="{'star': required}" class="label-info">{{textLabel}} </label>

  <select [compareWith]="compare"
          [disabled]="disabled"
          [formControlName]="fcName"
          [id]="fcName + i"
          [name]="fcName + i"
          [ngClass]="{'is-invalid': error, 'disabled': group.get(fcName).disabled }"
          class="form-control">
    <option *ngIf="!items || items.length === 0" value="">Aucun élément</option>
    <option *ngFor="let item of (disabled ? [] : items)" [ngValue]="item.id">{{item.name}}</option>
  </select>
  <div *ngIf="error" class="invalid-submit">
    <div>{{errorMsg}}</div>
  </div>

</div>

<div *ngIf="!group" class="d-flex flex-column"> <!-- Pas dans un reactive form -->
  <label *ngIf="textLabel" [for]="fcName" [ngClass]="{'star': required}" class="label-info">{{textLabel}} </label>
  <select [ngModel]="modelAttribute"
          (ngModelChange)="change($event)"
          [disabled]="disabled"
          [id]="fcName"
          [ngClass]="{'disabled': disabled}"
          class="form-control">
    <option *ngIf="!items || items.length === 0" value="">Aucun élément</option>
    <option *ngFor="let item of (disabled ? [] : items)" [ngValue]="item.id">{{item.name}}</option>
  </select>

</div>


