import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {ModuleSource} from '../../../model/enums/source/module-source.enum';
import {CurrentUserService} from '../../../services/current-user.service';

@Directive({
  selector: '[appHasModule]',
  standalone: true
})
export class HasModuleDirective {
  constructor(
    private readonly templateRef: TemplateRef<unknown>,
    private readonly viewContainer: ViewContainerRef,
    private readonly currentUserService: CurrentUserService
  ) {}

  @Input() set appHasModule(modules: ModuleSource[]) {
    if (this.currentUserService.checkModule(...modules)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
