<div class="d-flex flex-column fixed-size">
  <app-alert></app-alert>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">

      <div *ngIf="isPlaceSetting">
        <mat-tree-node *matTreeNodeDef="let node; when: isMaxPlaceLevel" matTreeNodePadding>
          <span>{{node.name}}</span>
          <button (click)="editNode(node)" mat-icon-button>
            <mat-icon>edit</mat-icon>
          </button>
          <button (click)="deleteNode(node)" mat-icon-button>
            <mat-icon>delete</mat-icon>
          </button>
        </mat-tree-node>
      </div>

      <div *ngIf="isEnsembleSetting">
        <mat-tree-node *matTreeNodeDef="let node; when: isMaxProjectLevel" matTreeNodePadding>
          <button mat-icon-button>
            <mat-icon class="mat-icon-rtl-mirror">
              remove
            </mat-icon>
          </button>
          <span>{{node.name}}</span>
          <span *ngIf="node.badge" [style.background-color]="node.badge.backgroundColor"
                [style.color]="node.badge.color"
                class="badge ml-2">
            {{ node.badge.text }}
        </span>
          <span *ngIf="node.thematique" [style.background-color]="node.thematique.backgroundColor"
                [style.color]="node.thematique.color"
                class="badge ml-2">
                        {{ node.thematique.text }}
        </span>
          <span *ngIf="node.date" [style.background-color]="node.date.backgroundColor" [style.color]="node.date.color"
                class="badge ml-2">
                        {{ node.date.text1 }} - {{ node.date.text2 }}
        </span>
          <button (click)="editNode(node)" mat-icon-button>
            <mat-icon>edit</mat-icon>
          </button>
          <button (click)="deleteNode(node)" mat-icon-button>
            <mat-icon>delete</mat-icon>
          </button>
        </mat-tree-node>
        <mat-tree-node *matTreeNodeDef="let node; when: isMaxProjectNew" class="pl-30" matTreeNodePadding>
          <span>{{node.name}}</span>
          <button (click)="addNewItem(node)" mat-icon-button>
            <mat-icon>add</mat-icon>
          </button>
        </mat-tree-node>
      </div>

      <div *ngIf="!isPlaceSetting && !isEnsembleSetting">
        <mat-tree-node *matTreeNodeDef="let node; when: isMaxSessionLevel" matTreeNodePadding>
          <span>{{node.name}}</span>
          <button (click)="editNode(node)" mat-icon-button>
            <mat-icon>edit</mat-icon>
          </button>
          <button (click)="deleteNode(node)" mat-icon-button>
            <mat-icon>delete</mat-icon>
          </button>
        </mat-tree-node>
      </div>


      <!-- This is the tree node template for expandable nodes -->
      <mat-tree-node *matTreeNodeDef="let node;" matTreeNodePadding>
        <button [attr.aria-label]="'toggle ' + node.name" mat-icon-button matTreeNodeToggle>
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <span>{{node.name}}</span>
        <span *ngIf="node.badge" [style.background-color]="node.badge.backgroundColor" [style.color]="node.badge.color"
              class="badge ml-2">
            {{ node.badge.text }}
        </span>
        <span *ngIf="node.thematique" [style.background-color]="node.thematique.backgroundColor"
              [style.color]="node.thematique.color"
              class="badge ml-2">
                        {{ node.thematique.text }}
      </span>
        <span *ngIf="node.date" [style.background-color]="node.date.backgroundColor" [style.color]="node.date.color"
              class="badge ml-2">
                        {{ node.date.text1 }} - {{ node.date.text2 }}
        </span>
        <button (click)="addNewItem(node)" mat-icon-button>
          <mat-icon>add</mat-icon>
        </button>
        <button (click)="editNode(node)" mat-icon-button>
          <mat-icon>edit</mat-icon>
        </button>
        <button (click)="deleteNode(node)" mat-icon-button>
          <mat-icon>delete</mat-icon>
        </button>
      </mat-tree-node>

    </mat-tree>
</div>
