<ng-container *ngIf="items?.length > 0">
  <div *ngIf="group && !category && !hasCompare"
       [formGroup]="group"
       class="no-underline form-group d-flex flex-column">
    <label *ngIf="textLabel"
           [for]="fcName"
           [ngClass]="{'star': required}" class="label-info">{{textLabel}} </label>
    <mat-select [disabled]="disabled"
                [formControlName]="fcName"
                [id]="fcName"
                [ngClass]="{'is-invalid': error, 'disabled': control.disabled }"
                class="custom-select"
                multiple
                placeholder="{{placeholder}}">
      <mat-select-trigger [matTooltip]="tooltipFormatted">
        {{formatedControlValue}}
      </mat-select-trigger>
      <mat-option *ngFor="let item of items" [value]="item.id" class="select-item">{{item.name}}</mat-option>
    </mat-select>
    <div *ngIf="error" class="invalid-submit">
      <div>{{errorMsg}}</div>
    </div>
  </div>

  <div *ngIf="group && !category && hasCompare"
       [formGroup]="group"
       class="no-underline form-group d-flex flex-column">
    <label *ngIf="textLabel"
           [for]="fcName"
           [ngClass]="{'star': required}" class="label-info">{{textLabel}} </label>
    <mat-select [compareWith]="compare"
                [disabled]="disabled"
                [formControlName]="fcName"
                [id]="fcName"
                [ngClass]="{'is-invalid': error, 'disabled': control.disabled }"
                class="custom-select"
                multiple
                placeholder="{{placeholder}}">
      <mat-select-trigger [matTooltip]="tooltipFormatted">
        {{formatedControlValue}}
      </mat-select-trigger>
      <mat-option *ngFor="let item of items" [value]="item.id" class="select-item">{{item.name}}</mat-option>
    </mat-select>
    <div *ngIf="error" class="invalid-submit">
      <div>{{errorMsg}}</div>
    </div>
  </div>

  <div *ngIf="!returnId && !group && !category" class="no-underline form-group d-flex flex-column">
    <label *ngIf="textLabel"
           [for]="fcName"
           [ngClass]="{'star': required}" class="label-info">{{textLabel}} </label>
    <mat-select [disabled]="disabled"
                [disabled]="disabled"
                [formControl]="control"
                [ngClass]="{'is-invalid': error, 'disabled': control.disabled }"
                class="custom-select"
                multiple
                placeholder="{{placeholder}}">

      <mat-select-trigger [matTooltip]="tooltipFormatted">
        {{formatedControlValue}}
      </mat-select-trigger>
      <mat-option *ngFor="let item of items" [value]="item.name" class="select-item">{{item.name}}</mat-option>
    </mat-select>
    <div *ngIf="error" class="invalid-submit">
      <div>{{errorMsg}}</div>
    </div>
  </div>


  <div *ngIf="returnId && !group && !category" class="no-underline form-group d-flex flex-column">
    <label *ngIf="textLabel"
           [for]="fcName"
           [ngClass]="{'star': required}" class="label-info">{{textLabel}} </label>
    <mat-select [disabled]="disabled"
                [formControl]="control"
                [ngClass]="{'is-invalid': error, 'disabled': control.disabled }"
                class="custom-select"
                multiple
                placeholder="{{placeholder}}">

      <mat-select-trigger [matTooltip]="tooltipFormatted">
        {{formatedControlValue}}
      </mat-select-trigger>
      <mat-option *ngFor="let item of items" [value]="item.id" class="select-item">{{item.name}}</mat-option>
    </mat-select>
    <div *ngIf="error" class="invalid-submit">
      <div>{{errorMsg}}</div>
    </div>
  </div>


  <div *ngIf="category" class="no-underline form-group d-flex flex-column">
    <label *ngIf="textLabel"
           [for]="fcName"
           [ngClass]="{'star': required}" class="label-info">{{textLabel}} </label>
    <mat-select [disabled]="disabled"
                [formControl]="control"
                [ngClass]="{'is-invalid': error, 'disabled': control.disabled}"
                class="custom-select"
                multiple
                placeholder="{{placeholder}}">

      <mat-select-trigger [matTooltip]="tooltipFormatted">
        {{formatedControlValue}}
      </mat-select-trigger>

      <mat-optgroup *ngFor="let item of items" [label]="item.name">
        <mat-option *ngFor="let sItem of item.listOfOptions" [value]="sItem.id" class="select-item">
          {{sItem.name}}
        </mat-option>
      </mat-optgroup>
    </mat-select>
    <div *ngIf="error" class="invalid-submit">
      <div>{{errorMsg}}</div>
    </div>
  </div>

</ng-container>
