import {Pipe, PipeTransform} from '@angular/core';
import {ReferentielEnum} from '../../../model/param/referentiel.enum';

@Pipe({
  name: 'referentiel',
  standalone: true
})
export class ReferentielPipe implements PipeTransform {
  hiddenParameters = [
    ReferentielEnum.CONTRIBUTION_CONTRACT,
    ReferentielEnum.DISPONIBILITE,
    ReferentielEnum.DOCUMENT,
    ReferentielEnum.EXPERIENCE_END_MOTIF,
    ReferentielEnum.FORMATION_TYPE,
    ReferentielEnum.INTERVIEW_PLACE,
    ReferentielEnum.MEANS_TRANSPORT,
    ReferentielEnum.MOBILITY,
    ReferentielEnum.PARCOURS,
    ReferentielEnum.PROFIL_JEUNE,
    ReferentielEnum.SOCIAL_MEDIA,
    ReferentielEnum.NEED_STATUS,
    ReferentielEnum.MONITORING_STATUS
  ];

  transform(
    params: {
      nbParam: number;
      name: string;
    }[]
  ): unknown {
    return params.filter((param) => !this.hiddenParameters.includes(param.nbParam));
  }
}
