import {Component, OnInit} from '@angular/core';
import {MatDialogActions, MatDialogContent, MatDialogRef} from '@angular/material/dialog';
import {MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule} from '@angular/material/tree';
import {FlatTreeControl} from '@angular/cdk/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {TreeItemFlatNode} from 'src/app/model/event/treeData/tree-item-flat-node.model';
import {TreeItemNode} from 'src/app/model/event/treeData/tree-item-node.model';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'app-select-multiple-tree-input',
  templateUrl: './select-multiple-tree-input.component.html',
  styleUrls: ['./select-multiple-tree-input.component.scss'],
  standalone: true,
  imports: [
    MatDialogContent,
    MatTreeModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatDialogActions
  ]
})
export class SelectMultipleTreeInputComponent implements OnInit {
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TreeItemFlatNode, TreeItemNode>();

  // tree ------------------------
  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TreeItemNode, TreeItemFlatNode>();
  /** A selected parent node to be inserted */
  selectedParent: TreeItemFlatNode | null = null;
  /** The new item's name */
  newItemName = '';
  treeControl: FlatTreeControl<TreeItemFlatNode>;
  treeFlattener: MatTreeFlattener<TreeItemNode, TreeItemFlatNode>;
  dataSource: MatTreeFlatDataSource<TreeItemNode, TreeItemFlatNode>;
  /** The selection for checklist */
  checklistSelection = new SelectionModel<TreeItemFlatNode>(true /* multiple */);

  data: TreeItemNode[];
  filter: number[];

  constructor(public dialogRef: MatDialogRef<SelectMultipleTreeInputComponent>) {}

  ngOnInit() {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<TreeItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    if (this.data) {
      if (this.data.length > 3) {
        this.dataSource.data = [
          {
            id: 0,
            listOfChilds: this.data,
            name: 'Tout'
          }
        ];
      } else {
        this.dataSource.data = this.data;
      }
    } else {
      this.dialogRef.close();
    }

    const itemNameList: number[] = this.loadTreeData();

    this.treeControl.dataNodes?.forEach((node) => {
      if (itemNameList.some((place) => node.id === place)) {
        this.checklistSelection.select(node);
      }
    });

    if (this.checklistSelection?.selected?.length === 0 && this.treeControl?.dataNodes) {
      this.treeControl.dataNodes.forEach((node) => this.checklistSelection.select(node));
    }
    this.expandSomeNode();
  }

  expandSomeNode() {
    let listTopLevel = this.treeControl.dataNodes?.filter(
      (value) => !this.treeControl.dataNodes.some((value1) => value.level < value1.level)
    );
    if (listTopLevel?.length < 5) {
      this.treeControl.expandAll();
    } else {
      listTopLevel = this.treeControl.dataNodes?.filter((value) => value.level === 0);
      if (listTopLevel && listTopLevel.length < 5) {
        listTopLevel.forEach((value) => {
          this.treeControl.expand(value);
        });
      }
    }
  }

  loadTreeData(): number[] {
    if (this.filter) {
      return this.filter;
    }
    return [];
  }

  getLevel = (node: TreeItemFlatNode) => node.level;

  isExpandable = (node: TreeItemFlatNode) => node.expandable;

  getChildren = (node: TreeItemNode): TreeItemNode[] => node.listOfChilds;

  hasChild = (_: number, _nodeData: TreeItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: TreeItemFlatNode) => _nodeData.name === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TreeItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.name === node.name ? existingNode : new TreeItemFlatNode();
    flatNode.name = node.name;
    flatNode.level = level;
    flatNode.expandable = !!node.listOfChilds;
    flatNode.id = node.id;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TreeItemFlatNode): boolean {
    if (node && this.treeControl.dataNodes) {
      const descendants = this.treeControl.getDescendants(node);
      const descAllSelected = descendants.every((child) =>
        this.checklistSelection.isSelected(child)
      );
      return descAllSelected;
    } else {
      return false;
    }
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TreeItemFlatNode): boolean {
    if (node && this.treeControl.dataNodes && this.treeControl.dataNodes.length > 0) {
      const descendants = this.treeControl.getDescendants(node);
      const result = descendants.some((child) => this.checklistSelection.isSelected(child));
      return result && !this.descendantsAllSelected(node);
    } else {
      return false;
    }
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TreeItemFlatNode): void {
    if (node) {
      this.checklistSelection.toggle(node);
      if (this.treeControl.dataNodes) {
        const descendants = this.treeControl.getDescendants(node);
        this.checklistSelection.isSelected(node)
          ? this.checklistSelection.select(...descendants)
          : this.checklistSelection.deselect(...descendants);

        // Force update for the parent
        descendants.every((child) => this.checklistSelection.isSelected(child));
        this.checkAllParentsSelection(node);
      }
    }
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TreeItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TreeItemFlatNode): void {
    let parent: TreeItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TreeItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) => this.checklistSelection.isSelected(child));
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TreeItemFlatNode): TreeItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onValidation() {
    this.dialogRef.close(this.checklistSelection.selected);
  }
}
