import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormSelectItem} from '../../../../../model/form/select-item.model';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgClass, NgFor, NgIf} from '@angular/common';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule, NgClass, NgFor]
})
export class SelectComponent {
  @Input() disabled: boolean;
  @Input() fcName: string; // Nom du FormControl
  @Input() error = false; // Nom du FormControl
  @Input() textLabel: string; // Label
  @Input() errorMsg: string; // Error message
  @Input() group; // Group contenant le FormControle
  @Input() items: Array<FormSelectItem>;
  @Input() i = 0; // i si l'input est dans une boucle (pour éviter d'avoir 2 composants ayant le même id
  @Input() required = false;

  @Input() modelAttribute: number; // Valeur entrée
  @Output() modelAttributeChange = new EventEmitter(); // Valeur envoyée au parent

  @Input() hasCompare = false;

  @Input() compare: (e1, e2) => boolean = () => null;

  change(newValue) {
    this.modelAttribute = newValue;
    this.modelAttributeChange.emit(newValue);
  }
}
