export enum PlaceEnum {
  CENTER = 0,
  TERRITORY = 1,
  ZONE = 2,
  REGION = 3,
  COUNTRY = 4
}

export enum PlaceTreeEnum {
  COUNTRY = 0,
  REGION = 1,
  ZONE = 2,
  TERRITORY = 3,
  CENTER = 4
}
