import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {TreeItemFlatNode} from 'src/app/model/event/treeData/tree-item-flat-node.model';
import {TreeItemNode} from 'src/app/model/event/treeData/tree-item-node.model';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatDialog} from '@angular/material/dialog';
import {MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule} from '@angular/material/tree';
import {TreeDataService} from 'src/app/services/tree-data.service';
import {PlaceWebservice} from 'src/app/services/webservices/place.webservice';
import {Place} from 'src/app/model/event/place/place.model';
import {PlaceEnum} from 'src/app/model/enums/place.enum';
import {AlertService} from 'src/app/services/alert.service';
import {DialogAddPlaceComponent} from 'src/app/components/shared/dialog/dialog-add-place/dialog-add-place.component';
import {DialogAddSessionComponent} from 'src/app/components/shared/dialog/dialog-add-session/dialog-add-session.component';
import {Session} from 'src/app/model/event/session.model';
import {SessionWebservice} from 'src/app/services/webservices/session.webservice';
import {SessionTypeEnum} from 'src/app/model/enums/session-type.enum';
import {FormatService} from 'src/app/services/format.service';
import {Destroyed} from '../../../directives/destroyed.directive';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {NgIf} from '@angular/common';
import {AlertComponent} from '../../../alert/alert/alert.component';

@Component({
  selector: 'app-tree-setting',
  templateUrl: './tree-setting.component.html',
  styleUrls: ['./tree-setting.component.scss'],
  standalone: true,
  imports: [AlertComponent, MatTreeModule, NgIf, MatButtonModule, MatIconModule]
})
export class TreeSettingComponent extends Destroyed implements OnInit, OnDestroy, OnChanges {
  @Input() isPlaceSetting: boolean;
  @Input() isEnsembleSetting: boolean;
  @Input() sessionType: SessionTypeEnum;
  @Output() editSessionSuccess = new EventEmitter();
  @Output() createClicked: EventEmitter<TreeItemFlatNode> = new EventEmitter<TreeItemFlatNode>();
  @Output() editClicked: EventEmitter<TreeItemFlatNode> = new EventEmitter<TreeItemFlatNode>();
  @Output() deleteClicked: EventEmitter<TreeItemFlatNode> = new EventEmitter<TreeItemFlatNode>();
  ensembleLength = 0;
  dataChange = new BehaviorSubject<TreeItemNode[]>([]);
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TreeItemFlatNode, TreeItemNode>();
  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TreeItemNode, TreeItemFlatNode>();
  treeControl = new FlatTreeControl<TreeItemFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );
  treeFlattener: MatTreeFlattener<TreeItemNode, TreeItemFlatNode>;
  dataSource: MatTreeFlatDataSource<TreeItemNode, TreeItemFlatNode>;

  constructor(
    private readonly treeDataService: TreeDataService,
    private readonly placeWebservice: PlaceWebservice,
    private readonly alertService: AlertService,
    private readonly dialog: MatDialog,
    private readonly sessionWS: SessionWebservice,
    private readonly formatService: FormatService
  ) {
    super();
    this.treeFlattener = new MatTreeFlattener(
      this._transformer,
      (node) => node.level,
      (node) => node.expandable,
      (node) => node.listOfChilds
    );
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  get treeData(): TreeItemNode[] {
    return this.dataChange.value;
  }

  _transformer = (node: TreeItemNode, level: number): TreeItemFlatNode => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.name === node.name ? existingNode : new TreeItemFlatNode();
    flatNode.id = node.id;
    flatNode.expandable = !!node.listOfChilds && node.listOfChilds.length > 0;
    flatNode.name = node.name;
    flatNode.level = level;
    flatNode.niveau = node.level;
    flatNode.badge = node.badge;
    flatNode.thematique = node.thematique;
    flatNode.date = node.date;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  hasChild = (node: TreeItemFlatNode) => node.expandable;

  getLevel = (node: TreeItemFlatNode) => node.level;

  isMaxSessionLevel = (_: number, _nodeData: TreeItemFlatNode) => !_nodeData.expandable;

  isMaxPlaceLevel = (_: number, _nodeData: TreeItemFlatNode) => _nodeData.level === 4;

  isMaxProjectLevel = (_: number, _nodeData: TreeItemFlatNode) => _nodeData.niveau === 2;

  isMaxProjectNew = (_: number, _nodeData: TreeItemFlatNode) => _nodeData.id === 0;

  ngOnInit() {
    if (this.isPlaceSetting) {
      this.treeDataService.allPlacesMessager.pipe(this.untilDestroyed()).subscribe((data) => {
        this.dataChange.next(data);
      });
    } else if (this.isEnsembleSetting) {
      this.treeDataService.allEnsembleMessager.pipe(this.untilDestroyed()).subscribe((data) => {
        this.dataChange.next(data);
        if (data[0] && data[0].items) {
          // @ts-ignore
          this.dataSource.data = data.items;
        }
      });
    } else {
      this.treeDataService.allSessionMessager.pipe(this.untilDestroyed()).subscribe((data) => {
        this.dataChange.next(data);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isEnsembleSetting && !changes.isEnsembleSetting.isFirstChange()) {
      this.treeDataService.allEnsembleMessager.pipe(this.untilDestroyed()).subscribe((data) => {
        this.dataChange.next(data);
      });
    } else {
      this.dataChange.pipe(this.untilDestroyed()).subscribe((value) => {
        this.dataSource.data = value;
        this.expandSomeNode();
      });
    }
  }

  expandSomeNode() {
    let listTopLevel = this.treeControl.dataNodes.filter(
      (value) => !this.treeControl.dataNodes.some((value1) => value.level < value1.level)
    );
    if (listTopLevel && listTopLevel.length < 5) {
      this.treeControl.expandAll();
    } else {
      listTopLevel = this.treeControl.dataNodes.filter((value) => value.level === 0);
      if (listTopLevel && listTopLevel.length < 5) {
        listTopLevel.forEach((value) => {
          this.treeControl.expand(value);
        });
      }
    }
  }

  addNewItem(node: TreeItemFlatNode) {
    if (this.isPlaceSetting) {
      const dialogRef = this.dialog.open(DialogAddPlaceComponent, {
        width: '350px'
      });

      dialogRef.afterClosed().subscribe((value: Place) => {
        if (value) {
          this.treeControl.expand(node);
          this.addPlace(node, value);
        }
      });
    } else if (this.isEnsembleSetting) {
      this.createClicked.emit(node);
    } else {
      const dialogRef = this.dialog.open(DialogAddSessionComponent, {
        width: '350px'
      });

      dialogRef.componentInstance.id = node.id;
      dialogRef.componentInstance.expandable = node.expandable;

      dialogRef.afterClosed().subscribe((value: Session) => {
        if (value) {
          this.addSession(node, value);
        }
      });
    }
  }

  insertItemPlace(parent: TreeItemNode, place: Place) {
    if (!parent.listOfChilds) {
      parent.listOfChilds = [];
    }
    parent.listOfChilds.push({
      id: place.id,
      name: place.nameCenter
    } as TreeItemNode);
    this.dataChange.next(this.treeData);
  }

  insertItemSession(parent: TreeItemNode, session: Session, isNewSession: boolean) {
    if (!parent.listOfChilds) {
      parent.listOfChilds = [];
    }

    if (isNewSession) {
      parent.listOfChilds.push({
        id: null,
        name: `Tranche : ${this.formatService.formatTrancheAge(session, false)}`,
        listOfChilds: []
      } as TreeItemNode);
      parent.listOfChilds[parent.listOfChilds.length - 1].listOfChilds.push({
        id: session.id,
        name: `Sous Tranche : ${this.formatService.formatTrancheAge(session, true)}`
      } as TreeItemNode);
    } else {
      parent.listOfChilds.push({
        id: session.id,
        name: `Sous Tranche : ${this.formatService.formatTrancheAge(session, true)}`
      } as TreeItemNode);
    }

    this.dataChange.next(this.treeData);
  }

  addSession(parentFlatNode: TreeItemFlatNode, session: Session) {
    if (this.sessionType === SessionTypeEnum.GENERIC) {
      session.sessionType = 1;
    } else if (this.sessionType === SessionTypeEnum.CLASSIC) {
      session.sessionType = 0;
    }

    this.sessionWS
      .postAddSession(session)
      .pipe(this.untilDestroyed())
      .subscribe(
        (data: Session) => {
          const parentNode = this.flatNodeMap.get(parentFlatNode);

          if (parentFlatNode.id === -1) {
            this.insertItemSession(parentNode, data, true);
          } else {
            this.insertItemSession(parentNode, data, false);
          }

          this.onSuccessAlert(`${data.name} : a été créé`);
        },
        (error) => {
          if (error.status === 400) {
            this.onErrorAlert(error.error);
          } else {
            this.onErrorAlert(`Une erreur est survenue lors de la sauvegarde de ${session.name}`);
          }
        },
        () => {}
      );
  }

  addPlace(parentFlatNode: TreeItemFlatNode, place: Place) {
    place.idParent = parentFlatNode.id;

    const placeEnumLength = Object.keys(PlaceEnum).length / 2;

    place.placeEnum = placeEnumLength - parentFlatNode.level - 2; // -2 pour le level de l'enfant et length -> index

    if (
      place.placeEnum === PlaceEnum.ZONE &&
      (place.proximity === null || place.proximity === '')
    ) {
      this.onErrorAlert('Le code doit être spécifié.');
      return;
    }

    this.placeWebservice
      .postAddPlace(place)
      .pipe(this.untilDestroyed())
      .subscribe({
        next: (data: Place) => {
          const parentNode = this.flatNodeMap.get(parentFlatNode);
          this.insertItemPlace(parentNode, data);
          this.onSuccessAlert(`${data.nameCenter} : a été créé`);
        },
        error: (error) => {
          if (error.status === 400) {
            this.onErrorAlert(error.error);
          } else {
            this.onErrorAlert(
              `Une erreur est survenue lors de la sauvegarde de ${place.nameCenter}`
            );
          }
        }
      });
  }

  deleteNode(node: TreeItemFlatNode) {
    if (this.isPlaceSetting) {
      this.deletePlace(node);
    } else if (this.isEnsembleSetting) {
      this.deleteClicked.emit(node);
    } else {
      this.deleteSession(node);
    }
  }

  deletePlace(node: TreeItemFlatNode) {
    this.placeWebservice
      .deletePlace(node.id)
      .pipe(this.untilDestroyed())
      .subscribe(
        (data) => {
          this.deleteNextStep(node);
        },
        (error) => {
          if (error.status === 400) {
            this.onErrorAlert(error.error);
          } else {
            this.onErrorAlert(`Une erreur est survenue lors de la suppression de ${node.name}`);
          }
        },
        () => {}
      );
  }

  deleteSession(node: TreeItemFlatNode) {
    this.sessionWS
      .deleteSession(node.id)
      .pipe(this.untilDestroyed())
      .subscribe({
        next: () => {
          this.deleteNextStep(node);
        },
        error: (error) => {
          if (error.status === 400) {
            this.onErrorAlert(error.error);
          } else {
            this.onErrorAlert(`Une erreur est survenue lors de la suppression de ${node.name}`);
          }
        }
      });
  }

  editNode(node: TreeItemFlatNode) {
    if (this.isPlaceSetting) {
      this.editPlace(node);
    } else if (this.isEnsembleSetting) {
      this.editClicked.emit(node);
    } else {
      this.editSession(node);
    }
  }

  editPlace(node: TreeItemFlatNode) {
    const dialogRef = this.dialog.open(DialogAddPlaceComponent);

    dialogRef.componentInstance.id = node.id;

    dialogRef.afterClosed().subscribe((value: Place) => {
      if (value) {
        this.placeWebservice
          .putUpdatePlace(value)
          .pipe(this.untilDestroyed())
          .subscribe(
            (data: Place) => {
              this.onSuccessAlert(`${data.nameCenter} : a été modifié`);
              node.name = data.nameCenter;
            },
            (error) => {
              this.onErrorAlert(`Une erreur est survenue lors de la modification de ${node.name}`);
            }
          );
      }
    });
  }

  editSession(node: TreeItemFlatNode) {
    const dialogRef = this.dialog.open(DialogAddSessionComponent);

    dialogRef.componentInstance.id = node.id;
    dialogRef.componentInstance.expandable = node.expandable;

    dialogRef.afterClosed().subscribe((value: Session) => {
      if (value) {
        this.sessionWS
          .putUpdateSession(value)
          .pipe(this.untilDestroyed())
          .subscribe({
            next: (data: Session) => {
              this.editSessionSuccess.emit();
              const sessionName = `Sous Tranche : ${this.formatService.formatTrancheAge(
                data,
                true
              )}`;
              this.onSuccessAlert(`${sessionName} : a été modifié`);
            },
            error: (error) => {
              this.onErrorAlert(`Une erreur est survenue lors de la modification de ${node.name}`);
            }
          });
      }
    });
  }

  /* Get the parent node of a node */
  getParentNode(node: TreeItemFlatNode): TreeItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  ngOnDestroy(): void {
    this.clearAlert();
  }

  onSuccessAlert(message: string) {
    this.clearAlert();
    this.alertService.success(message);
  }

  onErrorAlert(message: string) {
    this.clearAlert();
    this.alertService.error(message);
  }

  clearAlert() {
    this.alertService.clear();
  }

  private deleteNextStep(node: TreeItemFlatNode) {
    const parentNode = this.getParentNode(node);
    const neastedNode = this.flatNodeMap.get(parentNode);
    const index = neastedNode.listOfChilds.indexOf(node);
    neastedNode.listOfChilds.splice(index, 1);

    // supprimer le parent si vide
    if (neastedNode.listOfChilds.length === 0) {
      const parentpParentNode = this.getParentNode(parentNode);
      const parentNeastedNode = this.flatNodeMap.get(parentpParentNode);
      const parentIndex = parentNeastedNode.listOfChilds.indexOf(node);
      parentNeastedNode.listOfChilds.splice(parentIndex, 1);
    }

    this.dataChange.next(this.treeData);

    this.onSuccessAlert(`${node.name} : a été supprimé`);
  }
}
