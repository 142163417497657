export class TreeItemFlatNode {
  id: number;
  name: string;
  level: number;
  niveau?: number;
  badge?: TreeItemBadge;
  thematique?: TreeItemThematique;
  date?: TreeItemDate;
  expandable: boolean;
  disabled?: boolean;
}

export interface TreeItemBadge {
  text: string;
  backgroundColor?: string;
  color?: string;
}

export interface TreeItemThematique {
  text: string;
  backgroundColor?: string;
  color?: string;
}

export interface TreeItemDate {
  text1: string;
  text2: string;
  backgroundColor?: string;
  color?: string;
}
