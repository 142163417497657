import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormGroup} from '@angular/forms';
import {NgClass, NgIf} from '@angular/common';
import {Loader} from '@googlemaps/js-api-loader';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-input-text-google-autocomplete',
  templateUrl: './input-text-google-autocomplete.component.html',
  styleUrls: ['./input-text-google-autocomplete.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule, NgClass],
  providers: [
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: environment.googleMaps.apiKey,
        libraries: ['places'],
        language: 'fr'
      })
    }
  ]
})
export class InputTextGoogleAutocompleteComponent implements OnInit, AfterViewInit {
  @ViewChild('addressInput', {static: false}) addressInput: ElementRef<HTMLInputElement>;

  @Input() submitted: boolean; // Si le form a été soumis au moins une fois
  @Input() control: AbstractControl; // FormControl
  @Input() fcName: string; // Nom du FormControl
  @Input() textLabel: string; // Label
  @Input() required = false; // Si le form est requis ou non
  @Input() errorMsg: string; // Message si l'input est required
  @Input() errorMsg2 = ''; // Message si l'input est faux
  @Input() group: UntypedFormGroup; // Group contenant le FormControle
  @Input() i = 0; // i si l'input est dans une boucle (pour éviter d'avoir 2 composants ayant le même id
  @Input() placeholder: string;

  //autocomplete
  city: string = '';
  streetNumber: string = '';
  zip: string = '';
  address: string = '';

  autocomplete: google.maps.places.Autocomplete;

  constructor(private readonly loader: Loader) {}

  ngOnInit() {
    if (!this.placeholder) {
      this.placeholder = '';
    }
  }

  ngAfterViewInit() {
    this.loader.importLibrary('places').then(() => {
      this.initializeAutocomplete();
    });
  }

  isInvalid() {
    if (this.required) {
      return (
        this.control &&
        (this.control.dirty || this.control.touched || this.submitted) &&
        this.control.invalid
      );
    } else {
      return this.control && (this.control.dirty || this.control.touched) && this.control.invalid;
    }
  }

  initializeAutocomplete() {
    if (this.addressInput?.nativeElement) {
      this.autocomplete = new google.maps.places.Autocomplete(this.addressInput.nativeElement, {
        types: ['geocode'],
        fields: ['address_components']
      });
      this.autocomplete.setComponentRestrictions({country: 'FR'});

      // Check to bypass https://github.com/angular-ui/angular-google-maps/issues/270
      this.autocomplete.addListener('place_changed', () => {
        this.handleAddressChange(this.autocomplete.getPlace());
      });
    }
  }

  handleAddressChange(address: google.maps.places.PlaceResult) {
    address.address_components.forEach((add) => {
      add.types.map((item) => {
        switch (item) {
          case 'street_number':
            this.streetNumber = add.long_name;
            break;
          case 'route':
            this.address = add.long_name;
            break;
          case 'locality':
          case 'sublocality_level_1':
            this.city = add.long_name;
            break;
          case 'postal_code':
            this.zip = add.long_name;
            break;
          default:
            break;
        }
      });
    });

    this.group.patchValue({
      city: this.city,
      zipCode: this.zip,
      address1: `${this.streetNumber} ${this.address}`
    });
  }
}
