import {Injectable} from '@angular/core';
import {PageRequest} from 'src/app/model/request/pageRequest';
import {isNotNullOrUndefined, isNullOrUndefined} from 'src/app/utils/utils.static';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {
  public page: number;
  public pageMax: number;
  public nbElemMin: number;
  public nbElemMax: number;
  public count: number;

  private _pageRequest: PageRequest;

  get pageRequest(): PageRequest {
    return this._pageRequest;
  }

  set pageRequest(value: PageRequest) {
    this._pageRequest = value;
  }

  public getPage() {
    return this.page;
  }

  public getMaxPage() {
    return this.pageMax;
  }

  public getMinElem() {
    return this.nbElemMin;
  }

  public getMaxElem() {
    return this.nbElemMax;
  }

  public getCount() {
    return this.count;
  }

  public setPage(value: number) {
    this.page = value;
  }

  public setPageMax(value: number) {
    this.pageMax = value;
  }

  public setMaxElem(value: number) {
    this.nbElemMax = value;
  }

  public setMinElem(value: number) {
    this.nbElemMin = value;
  }

  public setCount(value: number) {
    this.count = value;
  }

  public resetMinMaxElem() {
    this.nbElemMin = null;
    this.nbElemMax = null;
  }

  public setPageNumber(value: number) {
    this._pageRequest.pageNumber = value;
  }

  setPagination(count: number) {
    this.setCount(count);

    if (this.count > this.pageRequest.pageSize * this.pageRequest.pageNumber) {
      this.setMaxElem(this.pageRequest.pageSize * this.pageRequest.pageNumber);
    } else {
      this.setMaxElem(this.count);
    }
    this.setPageMax(
      this.count >= this.pageRequest.pageSize
        ? Math.ceil(this.count / this.pageRequest.pageSize)
        : 1
    );
  }

  reset(): void {
    this.setPage(1);
    this.setMinElem(1);
    this.pageRequest.pageNumber = 1;
  }

  initPagination(itemPerPage: number, goToPageOne = false) {
    if (isNullOrUndefined(this.pageRequest)) {
      this.pageRequest = new PageRequest(1, itemPerPage);
      this.setPage(1);
      this.setMinElem(1);
    } else if (goToPageOne && isNotNullOrUndefined(this.pageRequest)) {
      this.setPageNumber(1);
      this.setPage(1);
      this.setMinElem(1);
    }
  }
}
