import {SexeEnum} from '../enums/sexe.enum';
import {Address} from '../event/place/address.model';
import {Young} from './young.model';
import {RelationUser} from './relation-user.model';
import {Year} from './year.model';
import {Timesheet} from '../event/timesheet.model';
import {ConnectedStatusEnum} from '../enums/connected-status.enum';
import {Intervenant} from './intervenant.model';
import {RelationBinome} from './relation-binome.model';

export class User {
  id: number;
  firstName: string;
  lastName: string;
  sex: SexeEnum;
  birthday: Date;
  phone: string;
  email: string;
  dateCreate: Date;
  dateUpdate: Date;
  active: boolean;

  idAddress: number;
  isConnected: ConnectedStatusEnum;
  isFromWeb: boolean;

  lastUpdateBy: User;
  createBy: User;
  idCreateBy?: number;
  idLastUpdateBy?: number;
  address: Address;
  young: Young;
  intervenant: Intervenant;

  listOfUser1: RelationUser[];
  listOfUser2: RelationUser[];
  listOfRelationBinomeYoung?: RelationBinome[];
  listOfYears: Year[];
  listOfTimesheet: Timesheet[];

  // display
  isTutor1?: boolean;
  isTutor2?: boolean;
  nameCenter?: string;
  year?: number;
  eventList?: number[];

  static formatAge(birthday: Date) {
    if (!birthday) {
      return 0;
    }
    birthday = new Date(birthday);
    const diff = Date.now() - birthday.getTime();
    const age = new Date(diff);
    return Math.abs(age.getUTCFullYear() - 1970);
  }

  convertToOneString(): string {
    let temp = `
      ${this.firstName}
      ${this.lastName}
      ${this.phone}
      ${this.birthday}
      ${User.formatAge(new Date(this.birthday))}
      ${this.email}
    `;
    if (this.address) {
      temp += `
        ${this.address.address1}
        ${this.address.address2}
        ${this.address.city}
        ${this.address.zipCode}
        ${this.address.country}
      `;
    }
    return temp;
  }

  contains(s: string): boolean {
    const temp = this.convertToOneString();
    return temp && temp.toLowerCase().includes(s);
  }
}
