export const isNotNullOrUndefined = <T>(input: null | undefined | T): input is T =>
  input !== null && input !== undefined;

export const isNullOrUndefined = <T>(input: null | undefined | T): input is T =>
  input === null || input === undefined;

export const trim = (input: string): string => (input && input !== '' ? input.trim() : '');

export const getEnumKeysAsNumber = <T>(input: T): number[] =>
  Object.keys(input)
    .filter((item) => !isNaN(Number(item)))
    .map((item) => Number(item));

export const getMobileToken = (): string => {
  return localStorage.getItem(
    'lpUe89U0cdHjA680yJSOBC75T8EaZB9o20oR4eeq4CBV4r6T68zim0k4Gmx87MspPP7JQ2c5f49BAj6Y' +
    'E7y3i5an5kViTJ6xU1J4NqJ2WW4g0m3KNubh5IWNCDL3EK0Z5pPrnd4UBAg566Q7996i43SyQk3QZ3kJ4AgoB68C0Q6t2VZ5ulMaNKbwTR9PefD9bg5Bg' +
    'nH8sFOhfi18Xu8bxwk10HzoGxInk0622mX9X6xvGNbH6T0ajGz4GZL9z456Bqf15rz168pzRiGM60b35Uh0xF8476T560iAi2ofDB3g1kaPpyVXcTbApN' +
    'ME52J9My0Gl2uu6I84V7tGK6w8173652c6GsEsn0jKprW5WV2uxjv3n11uMIp6cd6tIBtJe1RCELyttxNjzp1GWMCdA04ta3K95d5AipiDoOv3CC9nB55' +
    '9zSoFrhkl8eNJOSTGHp8mF0xws8kh84qL4Q54k6k8EH5K51hQjT6tNrRQP8641LL102n02x7LPV77hSZ2'
  );
};
