import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {fromEvent, Observable} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {AT_SIGN, BACKSPACE, F12, META, NINE, NUMPAD_ZERO, SPACE, ZERO} from '@angular/cdk/keycodes';
import {AbstractControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgClass, NgIf} from '@angular/common';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule, NgClass]
})
export class InputNumberComponent implements AfterViewInit {
  @Input() submitted: boolean; // Si le form a été soumis au moins une fois
  @Input() control: AbstractControl; // FormControl
  @Input() fcName: string; // Nom du FormControl
  @Input() textLabel: string; // Label
  @Input() group; // Group contenant le FormControle
  @Input() i = 0; // i si l'input est dans une boucle (pour éviter d'avoir 2 composants ayant le même id
  @Input() textLabel2 = '';
  @Input() modelAttribute: number; // Valeur entrée
  @Output() modelAttributeChange = new EventEmitter(); // Valeur envoyée au parent

  @Input() required = false;

  @Input() min = 0;
  @Input() max = 99;

  @ViewChild('input') input: ElementRef;

  observe: Observable<Event>;

  change(newValue) {
    this.modelAttribute = newValue;
    this.modelAttributeChange.emit(newValue);
  }

  ngAfterViewInit(): void {
    this.observe = fromEvent(this.input.nativeElement, 'keyup');
    this.observe.pipe(debounceTime(1000)).subscribe((c) => {
      this.resetNumber(c);
    });
  }

  omitSpecialChar(event) {
    const k = event.charCode; //         k = event.keyCode;  (Both can be used)
    return (
      (k > AT_SIGN && k < META) ||
      (k > NUMPAD_ZERO && k < F12) ||
      k === BACKSPACE ||
      k === SPACE ||
      (k >= ZERO && k <= NINE)
    );
  }

  resetNumber($event) {
    const value = $event.target.value;
    if (value) {
      if (this.min > 0 && value < this.min) {
        this.control.setValue(this.min);
      }

      if (this.max && value > this.max) {
        this.control.setValue(this.max);
      }
    }
  }

  removeChar($event) {
    const regex = /[a-zA-Z]+/g;
    if ($event.target.value.match(regex)) {
      $event.target.value = '';
    }
  }
}
