<h2 class="pb-4 text-center" mat-dialog-title>{{isUpdate ? 'Modifier ' : 'Ajouter '}} une relation binôme</h2>
<mat-dialog-content class="mat-typography">
  <div class="container">
    <div class="row">
      <ng-container *ngIf="isYoung">
        <div class="d-flex flex-row align-items-center col-md-6">
          <label>Jeune </label>
          <div class="d-flex flex-column col-10 justify-content-center  mb-2">{{this.filteredYoung.name}}</div>
        </div>
      </ng-container>

      <ng-container *ngIf="!isYoung">
        <div class="d-flex flex-row align-items-center col-md-6">
          <label>Jeune </label>
          <div class="d-flex flex-column col-10 justify-content-center align-items-center mb-2">
            <input
              (search)="getYoung($event)"
              [matAutocomplete]="young"
              [matTooltipPosition]="'right'"
              [ngClass]="fRelationBinome.jeune.valid ? 'selected' : ''"
              [formControl]="youngFormControl"
              class="form-control justify-content-center align-items-start"
              matTooltip="Saisir le nom d'un jeune inscrit à Job"
              type="search">
            <div *ngIf="fRelationBinome.jeune && fRelationBinome.jeune.invalid && youngChecker" class="invalid-submit">
              <div>Le jeune est obligatoire</div>
            </div>
            <mat-autocomplete #young="matAutocomplete" (optionSelected)='getYoung($event.option.value)'
                              [displayWith]="displayFn">
              <mat-option *ngFor="let item of filteredYoungObservable | async;"
                          [value]="item">{{item.firstName + " " + item.lastName + " " + (item.birthDate ? " | " + (item.birthDate | date) : "") }} </mat-option>
            </mat-autocomplete>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="isIntervenant">
        <div class="d-flex flex-row align-items-center col-md-6">
          <label>Intervenant </label>
          <div class="d-flex flex-column col-9 justify-content-center  mb-2">{{this.filteredIntervenant.name}}</div>
        </div>
      </ng-container>

      <ng-container *ngIf="!isIntervenant">
        <div class="d-flex flex-row align-items-center col-md-6">
          <label>Intervenant </label>
          <div class="d-flex flex-column col-9 justify-content-center align-items-center  mb-2">
            <input
              (search)="getIntervenant($event)"
              [matAutocomplete]="intervenant"
              [matTooltipPosition]="'right'"
              [ngClass]="fRelationBinome.intervenant.valid ? 'selected' : ''"
              [formControl]="intervenantFormControl"
              class="px-2 form-control justify-content-center align-items-start"
              matTooltip="Saisir le nom d'un intervenant préalablement créé dans la base"
              type="search">
            <div *ngIf="fRelationBinome.intervenant && fRelationBinome.intervenant.invalid && intervenantChecker"
                 class="invalid-submit">
              <div>L'intervenant est obligatoire</div>
            </div>
            <mat-autocomplete #intervenant="matAutocomplete" (optionSelected)='getIntervenant($event.option.value)'
                              [displayWith]="displayFn"
                              class="col-12">
              <mat-option *ngFor="let item of filteredIntervenantObservable | async;"
                          [value]="item">{{item.firstName + " " + item.lastName + " " + (item.entrepriseName ? " | " + item.entrepriseName : "") }} </mat-option>
            </mat-autocomplete>
          </div>
        </div>
      </ng-container>
    </div>
    <hr>
    <div class="row">

      <div [formGroup]="relationBinomeFormGroup" class="form-group col-md-6">
        <label>Date début du binôme</label>
        <div class="input-group">
          <input [matDatepicker]="dateBeginBinome" class="form-control" formControlName="dateBeginBinome" type="search">
          <div class="input-group-append">
            <button (click)="dateBeginBinome.open()" class="btn btn-outline-secondary form-control">
              <mat-icon>calendar_month</mat-icon>
            </button>
          </div>
        </div>
        <mat-datepicker #dateBeginBinome></mat-datepicker>
      </div>

      <div [formGroup]="relationBinomeFormGroup" class="form-group col-md-6">
        <label>Date de fin du binôme</label>
        <div class="input-group">
          <input [matDatepicker]="dateEndBinome" class="form-control" formControlName="dateEndBinome" type="search">
          <div class="input-group-append">
            <button (click)="dateEndBinome.open()" class="btn btn-outline-secondary form-control">
              <mat-icon>calendar_month</mat-icon>
            </button>
          </div>
        </div>
        <mat-datepicker #dateEndBinome></mat-datepicker>
      </div>


    </div>
    <div class="row">
      <app-select [error]="fRelationBinome.typeBinome && fRelationBinome.typeBinome.invalid && typeBinomeChecker"
                  [group]="relationBinomeFormGroup"
                  [items]="typeContactForSelect"
                  [required]="true"
                  class="col-md-6"
                  errorMsg="Le type du binome est obligatoire"
                  fcName="typeBinome"
                  textLabel="Type du binome"></app-select>
      <app-select
        *ngIf="!(fRelationBinome.typeBinome.value === TypeBinomeEnum.REFERENT_EXTERNE)"
        [error]="fRelationBinome.typeContactBinome && fRelationBinome.typeContactBinome.invalid && typeContactBinomeChecker"
        [group]="relationBinomeFormGroup"
        [items]="typeContactBinomeForSelect"
        [required]="true"
        class="col-md-6"
        errorMsg="La modalité est obligatoire"
        fcName="typeContactBinome"
        textLabel="Modalité"></app-select>
    </div>
    <div class="row">
      <app-select
        *ngIf="!(fRelationBinome.typeBinome.value === TypeBinomeEnum.REFERENT_EXTERNE)"
        [error]="fRelationBinome.dureeBinome && fRelationBinome.dureeBinome.invalid && dureeBinomeChecker"
        [group]="relationBinomeFormGroup"
        [items]="durationBinomeForSelect"
        [required]="true"
        class="col-md-6"
        errorMsg="La durée du binome est obligatoire"
        fcName="dureeBinome"
        textLabel="Durée du binôme"></app-select>

      <app-input-text [group]="relationBinomeFormGroup"
                      class="col-md-6"
                      fcName="commentaryEndBinome"
                      textLabel="Commentaire de fin du binôme"></app-input-text>
    </div>
    <div *ngIf="isParrainageYear" [formGroup]="relationBinomeFormGroup" class="row">

      <div class="form-group col-md-6">
        <label>Date d'accord du parrain (lancement ou renouvellement)</label>
        <div class="input-group">
          <input [matDatepicker]="dateSignatureMoralEngagementContract" class="form-control" formControlName="dateSignatureMoralEngagementContract" type="search">
          <div class="input-group-append">
            <button (click)="dateSignatureMoralEngagementContract.open()" class="btn btn-outline-secondary form-control">
              <mat-icon>calendar_month</mat-icon>
            </button>
          </div>
        </div>
        <mat-datepicker #dateSignatureMoralEngagementContract></mat-datepicker>
      </div>

      <div [formGroup]="relationBinomeFormGroup" class="form-group col-md-6">
        <label>Date de Signature des contrats</label>
        <div class="input-group">
          <input [matDatepicker]="dateSignatureSponsorshipActionPlan" class="form-control" formControlName="dateSignatureSponsorshipActionPlan" type="search">
          <div class="input-group-append">
            <button (click)="dateSignatureSponsorshipActionPlan.open()" class="btn btn-outline-secondary form-control">
              <mat-icon>calendar_month</mat-icon>
            </button>
          </div>
        </div>
        <mat-datepicker #dateSignatureSponsorshipActionPlan></mat-datepicker>
      </div>

    </div>
    <div class="row">
      <div class="form-group col-md-12" [formGroup]="relationBinomeFormGroup">
        <label>Suivi</label>
        <textarea class="col-lg-12" formControlName="suivi" matInput></textarea>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="container d-flex justify-content-evenly h-auto">
    <button (click)="isUpdate ? updateSelection() : validSelection()"
            class="btn btn-primary col-6" mat-button>{{isUpdate ? 'Modifier' : 'Ajouter'}}</button>
    <button (click)="cancelSelection()" class="btn btn-danger col-6" mat-button>Annuler</button>
  </div>
</mat-dialog-actions>

