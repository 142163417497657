<div [formGroup]="group" class="form-group">
  <label [for]="fcName+i" class="label-info">{{textLabel}}</label>
  <div class="input-group mb-2">
    <div class="input-group-prepend">
      <button (click)="changeValueMinus()" class="btn btn-outline-secondary btn_plus_minus text-center"
              id="nbSoeursMinus"
              type="button">-
      </button>
    </div>
    <input #input (keyup)="resetNumber($event)" [formControlName]="fcName"
           [id]="fcName + i"
           [max]="max"
           [min]="min"
           [name]="fcName + i"
           [ngClass]="{'is-invalid': (control.dirty || control.touched) && control.invalid, 'disabled': control.disabled}"
           class="form-control text-center"
           type="number">

    <div class="input-group-append">
      <button (click)="changeValuePlus()" class="btn btn-outline-secondary btn_plus_minus text-center" id="nbSoeursPlus"
              type="button">+
      </button>
    </div>
  </div>
</div>

