import {DestroyRef, inject, Injectable} from '@angular/core';
import {MonoTypeOperatorFunction} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Injectable()
export abstract class Destroyed {
  private readonly destroyRef = inject(DestroyRef);

  protected untilDestroyed<E>(): MonoTypeOperatorFunction<E> {
    return takeUntilDestroyed<E>(this.destroyRef);
  }
}
