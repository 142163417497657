<div *ngIf="group" [formGroup]="group" class="custom-control custom-radio">
  <input [formControlName]="fcName" [id]="idRadioBtn"
         [name]="fcName"
         [value]="valueRadioBtn"
         class="custom-control-input"
         type="radio">
  <label [for]="idRadioBtn" class="custom-control-label">{{textLabel}}</label>
</div>

<div *ngIf="!group" class="custom-control custom-radio">
  <input [id]="idRadioBtn" [value]="valueRadioBtn"
         class="custom-control-input"

         type="radio">
  <label [for]="idRadioBtn" class="custom-control-label">{{textLabel}}</label>
</div>

