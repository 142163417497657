import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FilterIntervenant} from '../../model/filter/filter-intervenant.model';
import {SpeakerLightDTO} from '../../model/dto/speaker-light.dto';
import {SpeakerDTO} from '../../model/dto/speaker.dto';
import {FilterUser} from '../../model/filter/filter-relation-binome/filter-user.model';
import {FilterRelationBinomeIntervenantDTO} from '../../model/filter/filter-relation-binome/filter-relation-binome-intervenant.dto';
import {FilterYoungJob} from '../../model/filter/filter-young-job.model';
import {PageRequest} from 'src/app/model/request/pageRequest';

@Injectable({
  providedIn: 'root'
})
export class SpeakerService {
  private readonly root = `${environment.envApiUrl}/speaker`;

  constructor(private readonly http: HttpClient) {}

  createSpeaker(intervenant: SpeakerDTO): Observable<number> {
    return this.http.post<number>(`${this.root}`, intervenant);
  }

  editSpeaker(intervenant: SpeakerDTO): Observable<number> {
    return this.http.put<number>(`${this.root}`, intervenant);
  }

  getIntervenant(filter: FilterIntervenant, pageRequest: PageRequest) {
    return this.http.post(`${this.root}/list`, filter, PageRequest.toQueryParams(pageRequest));
  }

  checkExisting(firstname, lastname) {
    return this.http.get(`${this.root}/${lastname}/${firstname}`);
  }

  getIntervenantBinome(filter: FilterYoungJob, idIntervenant: number, pageRequest: PageRequest) {
    return this.http.post(
      `${this.root}/binome/${idIntervenant}`,
      filter,
      PageRequest.toQueryParams(pageRequest)
    );
  }

  getYoungBinome(filter: FilterYoungJob, idYoung: number, pageRequest: PageRequest) {
    return this.http.post(
      `${this.root}/binomeYoung/${idYoung}`,
      filter,
      PageRequest.toQueryParams(pageRequest)
    );
  }

  getAllSpeakersByName(value: FilterUser): Observable<FilterRelationBinomeIntervenantDTO[]> {
    return this.http.post<FilterRelationBinomeIntervenantDTO[]>(`${this.root}/speakerName`, value);
  }

  getSpeaker(idSpeaker: number): Observable<SpeakerLightDTO> {
    return this.http.get<SpeakerLightDTO>(`${this.root}/` + idSpeaker);
  }
}
