import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormSelectItem} from 'src/app/model/form/select-item.model';
import {FormsModule, ReactiveFormsModule, UntypedFormControl, Validators} from '@angular/forms';
import {Destroyed} from '../../../directives/destroyed.directive';
import {isNotNullOrUndefined} from '../../../../../utils/utils.static';
import {MatOptionModule} from '@angular/material/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSelectModule} from '@angular/material/select';
import {NgClass, NgFor, NgIf} from '@angular/common';

@Component({
  selector: 'app-select-multiple',
  templateUrl: './select-multiple.component.html',
  styleUrls: ['./select-multiple.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    MatSelectModule,
    MatTooltipModule,
    NgFor,
    MatOptionModule
  ]
})
export class SelectMultipleComponent extends Destroyed implements OnInit {
  @Input() disabled: boolean;
  @Input() fcName: string; // Nom du FormControl
  @Input() error = false;
  @Input() errorMsg: string;
  @Input() items: Array<FormSelectItem>;
  @Input() textLabel: string; // Label
  @Input() i = 0; // i si l'input est dans une boucle (pour éviter d'avoir 2 composants ayant le même id
  @Input() group; // Group contenant le FormControle
  @Input() required = false;

  @Input() category = false;
  @Input() returnId = false;

  @Input() modelAttribute: Array<string | number>; // Valeur entrée
  @Output() modelAttributeChange = new EventEmitter(); // Valeur envoyée au parent
  placeholder: string;

  public control = new UntypedFormControl([{value: [], disabled: false}, Validators.required]);
  public formatedControlValue: string;
  public tooltipFormatted: string;

  @Input() hasCompare = false;

  constructor() {
    super();
  }

  @Input() compare: (e1, e2) => boolean = () => null;

  ngOnInit() {
    if (this.group && this.fcName && this.group.get(this.fcName)) {
      this.control = this.group.get(this.fcName);
    } else if (this.modelAttribute && this.modelAttribute.length > 0) {
      this.control.setValue(this.modelAttribute);
    }

    this.formatedControlValue = this.formatDisplay();
    this.tooltipFormatted = this.formatTooltip();

    this.control.valueChanges.pipe(this.untilDestroyed()).subscribe((val) => this.change(val));

    if (this.items && this.items.length > 0) {
      this.placeholder = 'Choisir un élément';
    } else {
      this.placeholder = 'Aucun élément';
    }
  }

  change(newValue) {
    this.formatedControlValue = this.formatDisplay();
    this.tooltipFormatted = this.formatTooltip();
    this.modelAttribute = newValue;
    this.modelAttributeChange.emit(newValue);
  }

  formatDisplay() {
    let value = '';
    if (!this.category) {
      value = this.controlValue(value);
    } else {
      if (this.control.value) {
        if (this.control.value.length > 0) {
          value =
            this.control.value.length +
            (this.control.value.length === 1 ? ' selectionné' : ' selectionnés');
        }
      }
    }

    return value;
  }

  controlValue(value) {
    if (isNotNullOrUndefined(this.control.value)) {
      if (isNotNullOrUndefined(this.control.value[0]) && Number(this.control.value[0]) > -1) {
        const temp = this.items.find((item) => item.id === Number(this.control.value[0]));
        value = temp ? temp.name : '';
      } else {
        value = this.control.value[0];
      }

      if (this.control.value.length > 1) {
        value += ' (+' + (this.control.value.length - 1);

        if (this.control.value.length === 2) {
          value += ' autre)';
        } else {
          value += ' autres)';
        }
      }
    }
    return value;
  }

  formatTooltip() {
    let value = '';
    if (
      this.group &&
      this.fcName &&
      this.group.get(this.fcName) &&
      isNotNullOrUndefined(this.control.value) &&
      this.control.value.length > 0
    ) {
      if (!this.category) {
        value = this.control.value
          .map((val) => this.items.find((item) => item.id === Number(val)).name)
          .join(', ');
      } else {
        value =
          this.control.value.length +
          (this.control.value.length === 1 ? ' selectionné' : ' selectionnés');
      }
    }

    return value;
  }
}
