import {PlaceEnum} from '../../enums/place.enum';
import {Address} from './address.model';
import {PlayOut} from '../play-out.model';
import {RoleUser} from '../../role/role-user.model';

export class Place {
  id: number;
  idParent: number;
  placeEnum: PlaceEnum;
  idYellowBox: number;
  nameCenter: string;
  adresseId: number;
  isActif: boolean;
  proximity: string;

  address: Address;
  parentPlace: Place;
  playOuts: PlayOut[];
  places: Place[];
  userRoles: RoleUser[];
}
