import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PaginationService} from '../../../services/pagination.service';
import {isNullOrUndefined} from '../../../utils/utils.static';
import {PageRequest} from 'src/app/model/request/pageRequest';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule} from '@angular/forms';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, MatIconModule]
})
export class PaginationComponent implements OnInit {
  page = 1;
  startElem = 1;

  @Input() itemPerPage: number;
  @Input() showItemPerPage: boolean = false;
  @Input() showHugeItemPerPage: boolean = false;

  @Output() pageChange = new EventEmitter<void>();

  constructor(public readonly paginationService: PaginationService) {}

  ngOnInit() {
    if (isNullOrUndefined(this.paginationService.getMinElem())) {
      this.paginationService.setMinElem(this.startElem);
    }
    if (!this.paginationService.getMaxElem()) {
      if (this.paginationService.getCount() > this.itemPerPage) {
        this.paginationService.setMaxElem(this.startElem + (this.itemPerPage - 1));
      } else {
        this.paginationService.setMaxElem(this.paginationService.getCount());
      }
    }
  }

  goPrevious() {
    if (this.paginationService.page > 1) {
      this.paginationService.setPage(--this.paginationService.page);
      this.paginationService.setMinElem((this.paginationService.page - 1) * this.itemPerPage + 1);
      this.paginationService.setMaxElem(this.paginationService.page * this.itemPerPage);
      this.paginationService.pageRequest = new PageRequest(
        this.paginationService.page,
        this.itemPerPage
      );
      this.pageChange.emit();
    }
  }

  goNext() {
    if (this.paginationService.page < this.paginationService.pageMax) {
      this.paginationService.setPage(++this.paginationService.page);
      if (this.paginationService.page === this.paginationService.pageMax) {
        this.paginationService.setMinElem(this.paginationService.getMinElem() + this.itemPerPage);
        this.paginationService.setMaxElem(this.paginationService.getCount());
      } else {
        this.paginationService.setMinElem(this.paginationService.getMinElem() + this.itemPerPage);
        this.paginationService.setMaxElem(this.paginationService.getMaxElem() + this.itemPerPage);
      }
      this.paginationService.pageRequest = new PageRequest(
        this.paginationService.page,
        this.itemPerPage
      );
      this.pageChange.emit();
    }
  }

  goEnd() {
    if (this.paginationService.page <= this.paginationService.pageMax - 1) {
      this.paginationService.setPage(this.paginationService.pageMax);
      this.paginationService.setMinElem(
        Math.floor(this.paginationService.getCount() / this.itemPerPage) * this.itemPerPage + 1
      );
      this.paginationService.setMaxElem(this.paginationService.getCount());
      this.paginationService.pageRequest = new PageRequest(
        this.paginationService.page,
        this.itemPerPage
      );
      this.pageChange.emit();
    }
  }

  goBegin() {
    if (this.paginationService.page !== 1) {
      this.paginationService.setPage(1);
      this.paginationService.setMinElem(1);
      this.paginationService.setMaxElem(this.itemPerPage);
      this.paginationService.pageRequest = new PageRequest(
        this.paginationService.page,
        this.itemPerPage
      );
      this.pageChange.emit();
    }
  }

  changeItemPerPage() {
    this.paginationService.setPage(1);
    this.paginationService.setMinElem(1);
    this.paginationService.setMaxElem(this.itemPerPage);
    this.paginationService.pageRequest = new PageRequest(
      this.paginationService.page,
      this.itemPerPage
    );
    this.pageChange.emit();
  }
}
