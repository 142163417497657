export enum DurationBinomeEnum {
  JOUR,
  THREE_MONTHS,
  SIX_MONTHS,
  ANNEE
}

export namespace DurationBinomeEnum {
  export function format(duration: number) {
    switch (DurationBinomeEnum[duration]) {
      case 'JOUR':
        return 'Une journée';
      case 'THREE_MONTHS':
        return 'Trois mois';
      case 'SIX_MONTHS':
        return 'Six mois';
      case 'ANNEE':
        return 'Un an';
      default:
        return '';
    }
  }
}
