import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {
  private readonly multipleFilters = new Map<string, unknown[]>();
  private readonly filters = new Map<string, unknown>();

  setMultipleFilter<T>(key: string, value: T[]) {
    return this.multipleFilters.set(key, value);
  }

  setFilter<T>(key: string, value: T): void {
    this.filters.set(key, value);
  }

  getMultipleFilter<T>(key: string): T[] | undefined {
    return this.multipleFilters.get(key) as T[] | undefined;
  }

  getFilter<T>(key: string): T | undefined {
    return this.filters.get(key) as T | undefined;
  }

  hasMultipleFilter(key: string): boolean {
    return this.multipleFilters.has(key);
  }

  hasFilter(key: string): boolean {
    return this.filters.has(key);
  }

  deleteMultipleFilter(key: string): boolean {
    return this.multipleFilters.delete(key);
  }

  deleteFilter(key: string): boolean {
    return this.filters.delete(key);
  }

  reset() {
    this.multipleFilters.clear();
    this.filters.clear();
  }
}
