import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {AlertService} from 'src/app/services/alert.service';
import {Session} from 'src/app/model/event/session.model';
import {SessionWebservice} from 'src/app/services/webservices/session.webservice';
import {Destroyed} from '../../directives/destroyed.directive';
import {AlertComponent} from '../../alert/alert/alert.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {InputNumberComponent} from '../../form-parts/form-items/input-number/input-number.component';

@Component({
  selector: 'app-dialog-add-session',
  templateUrl: './dialog-add-session.component.html',
  styleUrls: ['./dialog-add-session.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    InputNumberComponent,
    MatCheckboxModule,
    AlertComponent
  ]
})
export class DialogAddSessionComponent extends Destroyed implements OnInit, OnDestroy {
  id: number;
  expandable: boolean;

  result = false;
  formGroup: UntypedFormGroup;
  returnSession: Session = new Session();

  constructor(
    public readonly dialogRef: MatDialogRef<DialogAddSessionComponent>,
    private readonly fb: UntypedFormBuilder,
    private readonly alertService: AlertService,
    private readonly sessionWS: SessionWebservice
  ) {
    super();
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      minAgeGroup: [null, [Validators.required, Validators.min(6), Validators.max(99)]],
      maxAgeGroup: [null, [Validators.required, Validators.min(6), Validators.max(99)]],
      isActive: false
    });

    this.formGroup.addControl(
      'minAgeSubGroup',
      new UntypedFormControl([
        null,
        [
          Validators.required,
          Validators.min(this.formGroup.controls.minAgeGroup.value),
          Validators.max(this.formGroup.controls.maxAgeGroup.value)
        ]
      ])
    );
    this.formGroup.addControl(
      'maxAgeSubGroup',
      new UntypedFormControl([
        null,
        [
          Validators.required,
          Validators.min(this.formGroup.controls.minAgeGroup.value),
          Validators.max(this.formGroup.controls.maxAgeGroup.value)
        ]
      ])
    );

    if (this.id && this.id > 0) {
      this.sessionWS
        .getSessionById(this.id)
        .pipe(this.untilDestroyed())
        .subscribe((res: Session) => {
          if (!this.expandable) {
            this.returnSession.id = res.id;
            this.returnSession.sessionType = res.sessionType;
            this.formGroup.controls.minAgeSubGroup.setValue(res.minAgeSubGroup);
            this.formGroup.controls.maxAgeSubGroup.setValue(res.maxAgeSubGroup);
            this.formGroup.controls.isActive.setValue(res.isActive);
          }

          this.formGroup.controls.minAgeGroup.setValue(res.minAgeGroup);
          this.formGroup.controls.maxAgeGroup.setValue(res.maxAgeGroup);
        });

      this.formGroup.controls.minAgeGroup.disable();
      this.formGroup.controls.maxAgeGroup.disable();
    }

    this.formGroup.valueChanges.pipe(this.untilDestroyed()).subscribe((value) => {
      const val = this.formGroup.value;

      this.formGroup.controls.minAgeSubGroup.clearValidators();
      this.formGroup.controls.minAgeSubGroup.setValidators([
        Validators.required,
        Validators.min(val.minAgeGroup),
        Validators.max(val.maxAgeGroup)
      ]);
      this.formGroup.controls.maxAgeSubGroup.clearValidators();
      this.formGroup.controls.maxAgeSubGroup.setValidators([
        Validators.required,
        Validators.min(val.minAgeGroup),
        Validators.max(val.maxAgeGroup)
      ]);

      this.returnSession.minAgeGroup = this.formGroup.controls.minAgeGroup.value;
      this.returnSession.maxAgeGroup = this.formGroup.controls.maxAgeGroup.value;
      this.returnSession.minAgeSubGroup = val.minAgeSubGroup;
      this.returnSession.maxAgeSubGroup = val.maxAgeSubGroup;
      this.returnSession.name =
        'T ' +
        this.returnSession.minAgeGroup +
        (this.returnSession.maxAgeGroup > 17 ? 'et plus' : ' à ' + this.returnSession.maxAgeGroup) +
        ' - ST ' +
        this.returnSession.minAgeSubGroup +
        (this.returnSession.maxAgeSubGroup > 17
          ? 'et plus'
          : ' à ' + this.returnSession.maxAgeSubGroup);

      this.returnSession.isActive = val.isActive;
    });
  }

  yes() {
    if (this.formGroup.valid) {
      this.result = true;
      this.dialogRef.close(this.returnSession);
    } else {
      this.alertService.clear();
      this.alertService.error('Merci de saisir les informations obligatoires avant de valider');
    }
  }

  no() {
    this.result = false;
    this.dialogRef.close(null);
  }

  ngOnDestroy() {
    this.alertService.clear();
    if (this.result === false) {
      this.dialogRef.close(null);
    }
  }
}
