<ng-container *ngIf="textLabel2;else noGroup">
  <div [formGroup]="group" class="form-group">
    <label *ngIf="textLabel" [for]="fcName+i" [ngClass]="{'star': required}" class="label-info">{{textLabel}}</label>
    <div class="input-group mb-2">
      <input #input (keypress)="omitSpecialChar($event)"
             (keyup)="removeChar($event)"
             [formControlName]="fcName"
             [id]="fcName+i"
             [max]="max"
             [min]="min"
             [name]="fcName + i"
             [ngClass]="{'is-invalid': (control.dirty || control.touched) && control.invalid, 'disabled': control.disabled}"
             class="form-control text-center"
             type="number">
      <div class="input-group-append">
        <div class="input-group-text">{{textLabel2}}</div>
      </div>
    </div>
  </div>
</ng-container>


<ng-template #noGroup>
  <div [formGroup]="group" class="form-group">
    <label *ngIf="textLabel" [for]="fcName+i" [ngClass]="{'star': required}" class="label-info">{{textLabel}}</label>
    <input #input
           (keyup)="removeChar($event)"
           [formControlName]="fcName"
           [id]="fcName+i"
           [max]="max"
           [min]="min"
           [name]="fcName + i"
           [ngClass]="{'is-invalid': (control.dirty || control.touched) && control.invalid, 'disabled': control.disabled}"
           class="form-control text-center"
           type="number">
  </div>
</ng-template>
