export enum SexeEnum {
  MAN = 0,
  WOMAN = 1,
  OTHER = 2
}

export enum SexeSponsorEnum {
  MAN = 0,
  WOMAN = 1,
  INDIFFERENT
}
