import {Injectable} from '@angular/core';
import {DatePipe} from '@angular/common';
import {StatusEnum} from '../model/enums/status.enum';
import {SexeEnum, SexeSponsorEnum} from '../model/enums/sexe.enum';
import {PlaceEnum} from '../model/enums/place.enum';
import {Address} from '../model/event/place/address.model';
import {SimplePlayOutDTO} from '../model/dto/simple-play-out.dto';
import {RoleEnum} from '../model/enums/role.enum';
import {TreeItemFlatNode} from '../model/event/treeData/tree-item-flat-node.model';
import {RelationUserEnum} from '../model/enums/relation-user.enum';
import {User} from '../model/user/user.model';
import {ExportTypeEnum} from '../model/enums/export-type.enum';
import {PlayOut} from '../model/event/play-out.model';
import {AvailablePeriodEnum} from '../model/enums/available-period.enum';
import {SponsorChoiceFilter} from '../model/enums/sponsor-choice-filter.enum';
import {WorkDurationEnum} from '../model/enums/work-duration.enum';
import {InterviewDurationEnum} from '../model/enums/interview-duration.enum';
import {AnalyzeDocEnum} from '../model/enums/analyze-doc.enum';
import {DashboardType} from '../model/dashboard/dashboard.enum';
import {VisitFamilyPlaceEnum} from '../model/enums/visit-family-place.enum';
import {VisitDurationEnum} from '../model/enums/visit-duration-enum';
import {VisitStatusEnum} from '../model/enums/visit-status.enum';
import {FormatDateHelper} from '../utils/helpers/format-date.helper';

@Injectable({
  providedIn: 'root'
})
export class FormatService {
  constructor(private readonly datePipe: DatePipe) {}

  formatRoleEnum(index: number) {
    const roleEnum = RoleEnum[index];
    switch (roleEnum) {
      case 'YOUNG':
        return 'Jeune';

      case 'MANAGER':
        return 'Manager';

      case 'INTEGRATION_RESPONSIBLE':
        return "Responsable d'intégration";

      case 'ADMINISTRATOR':
        return 'Administrateur';

      case 'SUPER_MANAGER':
        return 'Super manager';
      default:
        return '';
    }
  }

  formatVisitDurationEnum(index: number) {
    const visitDurationEnum = VisitDurationEnum[index];
    switch (visitDurationEnum) {
      case 'ONE_HOUR_AND_A_HALF':
        return '1h30';
      case 'ONE_HOUR':
        return '1h00';
      case 'THIRTY_MINUTES':
        return '30min';
      default:
        return '';
    }
  }

  formatPlaceEnum(index: number) {
    const placeEnum = PlaceEnum[index];
    switch (placeEnum) {
      case 'CENTER':
        return 'Centre';

      case 'TERRITORY':
        return 'Territoire';

      case 'ZONE':
        return 'Zone';

      case 'REGION':
        return 'Région';

      case 'COUNTRY':
        return 'Pays';
      default:
        return '';
    }
  }

  formatTypeActiviteForReporting(index: number) {
    switch (index) {
      case 1:
        return 'Séances sportives';

      case 2:
        return 'Tournois / Rencontres sportives';

      case 3:
        return 'Matchs';

      case 4:
        return 'Camps et séjours';

      case 5:
        return 'Evénements fédérateurs';

      case 6:
        return 'Sorties culturelles';

      case 7:
        return 'Ateliers nutrition';
      default:
        return '';
    }
  }

  formatStatusEnum(index: number, isJob = false) {
    const status = StatusEnum[index];
    switch (status) {
      case 'CREATED':
        return 'Créé';

      case 'IN_PROGRESS':
        return 'En cours';

      case 'VALIDATED':
        return isJob ? 'Clôturé' : 'Validé';

      case 'CANCELED':
        return 'Annulé';
      default:
        return '';
    }
  }

  formatVisitStatusEnum(index: number) {
    const status = VisitStatusEnum[index];
    switch (status) {
      case 'CREATED':
        return 'Crée';
      case 'IN_PROGRESS':
        return 'En cours';
      case 'ENDED':
        return 'A signer';
      case 'SIGNED':
        return 'Signé';
      default:
        return '';
    }
  }

  formatStatusIntervenant(value: boolean) {
    if (value) {
      return 'Actif';
    } else {
      return 'Inactif';
    }
  }

  formatIsValidatedStatus(isValid: boolean | undefined | null) {
    switch (isValid) {
      case true:
        return 'Valide';

      case false:
        return 'En attente';

      default:
        return "Confirmation d'inscription";
    }
  }

  formatIsValidatedJobStatus(isValid: boolean | undefined | null) {
    switch (isValid) {
      case true:
        return 'Actif';

      case false:
        return 'En attente autorisation / Sorti';

      default:
        return "Confirmation d'inscription";
    }
  }

  formatStatusEnumColor(index: number) {
    const status = StatusEnum[index];
    switch (status) {
      case 'CREATED':
        return 'created';

      case 'IN_PROGRESS':
        return 'in-progress';

      case 'VALIDATED':
        return 'validated';

      case 'CANCELED':
        return 'canceled';
      default:
        return '';
    }
  }

  formatRelation(index: number) {
    const status = RelationUserEnum[index];
    switch (status) {
      case 'TUTOR1':
        return 'Tuteur principal';
      case 'TUTOR2':
        return 'Tuteur secondaire';
      case 'SIBLING':
        return 'Frère/sœur';
      case 'RI':
        return "Responsable d'intégration";
      case 'SPONSOR':
        return 'Parrain';
      default:
        return '';
    }
  }

  formatExportTypeEnum(index: number) {
    const status = ExportTypeEnum[index];
    switch (status) {
      case 'EXPORT_NB_JEUNE_INSCRIT':
        return 'Export des inscriptions (Stat)';
      case 'EXPORT_GLOBAL_JEUNE':
        return 'Export global des jeunes';
      case 'EXPORT_NOMBRE_MOYEN_JEUNE':
        return 'Export des participations - Séances Sportives';
      case 'EXPORT_GLOBAL_INSCRIPTION':
        return 'Export global des inscriptions';
      case 'EXPORT_NOMBRE_JEUNE_NON_PRESENT':
        return 'Export des jeunes non présents';
      case 'EXPORT_NOMBRE_INSCRIPTION_SUPPRIME':
        return 'Export des inscriptions à supprimer';
      default:
        return '';
    }
  }

  formatMandatory(mandatory: boolean) {
    if (mandatory) {
      return 'Oui';
    } else {
      return 'Non';
    }
  }

  formatAddress(address: Address) {
    return address ? `${address.address1}, ${address.zipCode} ${address.city}` : '';
  }

  formatDate(date: Date): string {
    return FormatDateHelper.formatDate(date);
  }

  formatDateDetail(date: Date): string {
    return FormatDateHelper.formatDateDetail(date);
  }

  formatDateWithHour(date: Date): string {
    return FormatDateHelper.formatDateWithHour(date);
  }

  formatDateRange(dateBegin: Date, dateEnd: Date): string {
    return FormatDateHelper.formatDateRange(dateBegin, dateEnd);
  }

  formatDateWeb(date: string): string {
    return FormatDateHelper.formatDateWeb(date);
  }

  formatLargeDate(date: Date): string {
    return FormatDateHelper.formatLargeDate(date);
  }

  formatNbOfMinToTime(nbMin: number) {
    const nbHours = Math.floor(nbMin / 60);
    const nbMinutes = nbMin % 60;
    let formatedHours: string = nbHours.toString();
    let formatedMinutes: string = nbMinutes.toString();

    if (nbHours < 10) {
      formatedHours = `0${nbHours}`;
    }
    if (nbMinutes < 10) {
      formatedMinutes = `0${nbMinutes}`;
    }

    return `${formatedHours}:${formatedMinutes}`;
  }

  formatTrancheAgeComplet(event) {
    // Formatage des infos de la colonne tranche d'âge
    let res = '';
    if (event.maxAgeGroup && event.minAgeGroup) {
      res += `Tranche : ${event.minAgeGroup} à ${this.formatMaxAge(event.maxAgeGroup)} ans`;
    }
    if (res !== '' && event.maxAgeSubGroup && event.minAgeSubGroup) {
      res += ` - Sous-tranche : ${event.minAgeSubGroup} à ${this.formatMaxAge(
        event.maxAgeSubGroup
      )} ans`;
    } else if (event.maxAgeSubGroup && event.minAgeSubGroup) {
      res += `Sous-tranche : ${event.minAgeSubGroup} à ${this.formatMaxAge(
        event.maxAgeSubGroup
      )} ans`;
    }
    return res;
  }

  formatTrancheAge(event, isSub: boolean) {
    // Formatage des infos de la colonne tranche d'âge
    let res = '';
    if (isSub && event.maxAgeSubGroup && event.minAgeSubGroup) {
      res = `${event.minAgeSubGroup} à ${this.formatMaxAge(event.maxAgeSubGroup)} ans`;
    } else if (!isSub && event.maxAgeGroup && event.minAgeGroup) {
      res = `${event.minAgeGroup} à ${this.formatMaxAge(event.maxAgeGroup)} ans`;
    }
    return res;
  }

  formatMaxAge(age: number) {
    if (age > 17) {
      return '17+';
    } else {
      return age.toString();
    }
  }

  formatTrancheAgeYearStartEvent(dateBegin: Date) {
    const dateBeginEvent = new Date(dateBegin);
    return dateBeginEvent.getMonth() < 8
      ? dateBeginEvent.getFullYear() - 1
      : dateBeginEvent.getFullYear();
  }

  formatName(user) {
    if (user) {
      return `${user.lastName} ${user.firstName}`;
    } else {
      return 'indéfini';
    }
  }

  formatNameIntervenant(intervenant) {
    if (intervenant) {
      return `${intervenant.nom} ${intervenant.prenom}`;
    } else {
      return 'indéfini';
    }
  }

  formatNameRi(firstNameRi, lastNameRi) {
    if (lastNameRi && firstNameRi) {
      return `${lastNameRi} ${firstNameRi}`;
    } else {
      return '';
    }
  }

  formatSexSponsor(sex) {
    switch (SexeSponsorEnum[sex]) {
      case 'MAN':
        return 'Homme';

      case 'WOMAN':
        return 'Femme';

      case 'INDIFFERENT':
        return 'Indifférent';
      default:
        return '';
    }
  }

  formatSex(sex, complet: boolean) {
    if (complet) {
      switch (SexeEnum[sex]) {
        case 'MAN':
          return 'Homme';

        case 'WOMAN':
          return 'Femme';

        case 'OTHER':
          return 'Autre';
        default:
          return '';
      }
    } else {
      switch (SexeEnum[sex]) {
        case 'MAN':
          return 'H';

        case 'WOMAN':
          return 'F';

        case 'OTHER':
          return 'A';
        default:
          return '';
      }
    }
  }

  formatSexYoung(sex, complet: boolean) {
    if (complet) {
      switch (SexeEnum[sex]) {
        case 'MAN':
          return 'Garçon';

        case 'WOMAN':
          return 'Fille';

        case 'OTHERS':
          return 'Autre';
        default:
          return '';
      }
    } else {
      switch (SexeEnum[sex]) {
        case 'MAN':
          return 'G';

        case 'WOMAN':
          return 'F';

        case 'OTHERS':
          return 'A';
        default:
          return '';
      }
    }
  }

  formatMeansTransportEnum(transport) {
    switch (transport) {
      case 'NON':
        return 'Non';
      case 'NON_RENSEIGNE':
        return 'Non renseigné';
      case 'TRANSPORT_COMMUN':
        return 'Transport en commun';
      case 'VOITURE':
        return 'Voiture';
      case 'SCOOTER_MOTO':
        return 'Scooter / Moto';
      case 'TROTTINETTE_VELO':
        return 'Trottinette / Vélo';
      default:
        return '';
    }
  }

  formatHandicapEnum(handicap) {
    switch (handicap) {
      case 'NON':
        return 'Non';
      case 'OUI':
        return 'Oui';
      case 'EN_COURS':
        return 'En cours';
      default:
        return '';
    }
  }

  formatSchoolStatusEnum(status) {
    switch (status) {
      case 'SCHOOLED':
        return 'Scolarisé';
      case 'UNSCHOOLED':
        return 'Descolarisé';
      case 'COMPLETED_STUDIES':
        return 'Etudes terminées';
      default:
        return '';
    }
  }

  formatEligibilityFseEnum(status) {
    switch (status) {
      case 'NOT_ELIGIBLE':
        return 'Non éligible';
      case 'POTENTIALLY_ELIGIBLE':
        return 'Potentiellement éligible';
      case 'ALMOST_ELIGIBLE':
        return 'Presque éligible';
      case 'ELIGIBLE':
        return 'Eligible';
      default:
        return '';
    }
  }

  formatHighestLevelEnum(diploma: string) {
    const diplomaMapping = {
      NO_BASIC_KNOWLEDGE_DIPLOMA: 'Pas de diplôme',
      NO_PROFESSIONAL_SKILLS_DIPLOMA: 'Pas de diplôme savoirs faire professionnels',
      DNB: 'DNB',
      CAP_BEP: 'CAP/BEP',
      BACCALAUREAT: 'Baccalauréat',
      BAC_1: 'Bac +1',
      BAC_2: 'Bac +2',
      BAC_3: 'Bac +3',
      BAC_4: 'Bac +4',
      BAC_5: 'Bac +5'
    };

    return diplomaMapping[diploma];
  }

  formatListDisplay(list: string[]) {
    let value = '';
    if (list && list.length > 0) {
      value += list[0];

      if (list.length > 1) {
        value += ` (+${list.length - 1}`;

        if (list.length === 2) {
          value += ' autre)';
        } else {
          value += ' autres)';
        }
      }
    }
    return value;
  }

  formatListPlayoutDTO(list: SimplePlayOutDTO[]) {
    let value = '';
    if (list && list.length > 0) {
      value += list[0].centerName;

      if (list.length > 1) {
        value += ` (+${list.length - 1}`;

        if (list.length === 2) {
          value += ' autre)';
        } else {
          value += ' autres)';
        }
      }
    }
    return value;
  }

  formatListPlayout(list: PlayOut[]) {
    let value = '';
    if (list && list.length > 0) {
      value += list[0].place.nameCenter;

      if (list.length > 1) {
        value += ` (+${list.length - 1}`;

        if (list.length === 2) {
          value += ' autre)';
        } else {
          value += ' autres)';
        }
      }
    }
    return value;
  }

  formatPlaceTreeInputDisplay(choixPlaceList: TreeItemFlatNode[]): string {
    const placeTopLevel = choixPlaceList.filter(
      (value) => !choixPlaceList.some((value1) => value.level < value1.level)
    );

    let output = '';
    if (placeTopLevel.length > 0) {
      output += placeTopLevel[0].name;

      if (placeTopLevel.length > 1) {
        output += ` (+${placeTopLevel.length - 1}`;

        if (placeTopLevel.length === 2) {
          output += ' autre)';
        } else {
          output += ' autres)';
        }
      }
    }
    return output;
  }

  formatTitlePlaceTreeInputDisplay(choixPlaceList: TreeItemFlatNode[]): string {
    const placeTopLevel = choixPlaceList.filter(
      (value) => !choixPlaceList.some((value1) => value.level < value1.level)
    );
    let result = '';
    placeTopLevel.forEach((value) => {
      if (result) {
        result += `, ${value.name}`;
      } else {
        result = value.name;
      }
    });
    return result;
  }

  formatBaseTreeInputDisplay(choixList: TreeItemFlatNode[]): string {
    let value = '';
    if (choixList.length > 0) {
      value += choixList[0].name;

      if (choixList.length > 1) {
        value += ` (+${choixList.length - 1}`;

        if (choixList.length === 2) {
          value += ' autre)';
        } else {
          value += ' autres)';
        }
      }
    }
    return value;
  }

  // cleanArray removes all duplicated elements
  cleanArray(array: string[]) {
    return array.filter(
      (value, index, arr) =>
        arr.findIndex((value2) => JSON.stringify(value) === JSON.stringify(value2)) === index
    );
  }

  formatAge(birthday: Date) {
    return User.formatAge(birthday);
  }

  formatAgeTranche(year: Date, creationDate: Date) {
    return this.formatTrancheAgeYearStartEvent(creationDate) - new Date(year).getFullYear();
  }

  formatPhone(num: string, code = '+33') {
    if (!num?.startsWith('+33')) {
      if (num?.length === 10) {
        return code + num.slice(1);
      } else if (num?.length === 9) {
        return code + num;
      } else if (num?.startsWith('0')) {
        return code + num.slice(1);
      } else {
        return num;
      }
    } else {
      return num;
    }
  }

  cleanString(str: string): string {
    return str?.replace(
      /[\u007F-\u009F\u061C\u200E\u200F\u202A-\u202E\u2066-\u2069\u200B-\u200F\uFEFF]/g,
      ''
    );
  }

  clearAccent(str) {
    const map = {
      ' ': '_|-',
      a: 'á|à|ã|â|À|Á|Ã|Â',
      e: 'é|è|ê|ë|É|È|Ê|Ë',
      i: 'í|ì|î|Í|Ì|Î|Ï',
      o: 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
      u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
      c: 'ç|Ç',
      n: 'ñ|Ñ'
    };

    for (const pattern in map) {
      if (map.hasOwnProperty(pattern)) {
        str = str.replace(new RegExp(map[pattern], 'g'), pattern);
      }
    }

    return str;
  }

  trim(text: string): string {
    return text && text !== '' ? text.trim() : '';
  }

  removeCharFromDate($event) {
    const regex = /[a-zA-Z]+/g;
    if ($event.target.value.match(regex)) {
      $event.target.value = '';
    }
  }

  formatBoolean(value: boolean) {
    if (value) {
      return 'Oui';
    } else if (!value) {
      return 'Non';
    } else {
      return undefined;
    }
  }

  formatStatutIntervenant(value: boolean) {
    if (value) {
      return 'Actif';
    } else if (!value) {
      return 'Inactif';
    } else {
      return undefined;
    }
  }

  formatOnlyBooster(onlyBooster: boolean | undefined | null) {
    if (onlyBooster === undefined || onlyBooster === null) {
      return 'Les deux';
    } else {
      if (onlyBooster) {
        return 'Avec';
      } else {
        return 'Sans';
      }
    }
  }

  formatAvailablePeriodEnum(index: number) {
    const status = AvailablePeriodEnum[index];
    switch (status) {
      case 'OCT_DEC':
        return 'Oct-Dec';
      case 'JANV_MARS':
        return 'Janv-Mars';
      case 'AVRIL_JUIN':
        return 'Avril-Juin';
      default:
        return '';
    }
  }

  formatSponsorChoice(index: number) {
    const status = SponsorChoiceFilter[index];
    switch (status) {
      case 'DONT_WANT_SPONSOR':
        return 'Ne souhaite pas de parrain';
      case 'HAS_SPONSOR':
        return 'A un parrain';
      case 'DONT_HAVE_SPONSOR_AND_WANT_ONE':
        return "Souhaite un parrain et n'en a pas";
      default:
        return '';
    }
  }

  formatVisitFamilyPlace(index: number) {
    const place = VisitFamilyPlaceEnum[index];
    switch (place) {
      case 'FAMILY_HOME':
        return 'Domicile de la famille';
      case 'PARTNER_PREMISES':
        return 'Locaux du partenaire';
      case 'SDLV_OFFICE':
        return 'Bureau SDLV';
      default:
        return '';
    }
  }

  formatWorkDuration(index: number) {
    switch (WorkDurationEnum[index]) {
      case 'ALL_TIME':
        return 'Temps plein';
      case 'HALF_TIME_DOWN':
        return 'Temps partiel <50%';
      case 'HALF_TIME_UP':
        return 'Temps partiel >50%';
      default:
        return '';
    }
  }

  formatInterviewDuration(status) {
    switch (status) {
      case 'ZERO':
        return 'Absent';
      case 'HALF':
        return '30min';
      case 'ONE':
        return '1h';
      case 'ONE_AND_A_HALF':
        return '1h30';
      case 'TWO':
        return '2h';
      default:
        return '';
    }
  }

  convertInterviewDurationToNumber(status): number {
    switch (status) {
      case 'HALF':
        return 30;
      case 'ONE':
        return 60;
      case 'ONE_AND_A_HALF':
        return 90;
      case 'TWO':
        return 120;
      case 'ZERO':
        return 0;
      default:
        return 0;
    }
  }

  convertDurationToInterviewDurationEnum(duration: number) {
    if (duration === 0) {
      return InterviewDurationEnum.ZERO;
    } else if (duration > 0 && duration < 31) {
      return InterviewDurationEnum.HALF;
    } else if (duration > 30 && duration < 61) {
      return InterviewDurationEnum.ONE;
    } else if (duration > 60 && duration < 91) {
      return InterviewDurationEnum.ONE_AND_A_HALF;
    } else {
      return InterviewDurationEnum.TWO;
    }
  }

  formatAnalyzedDoc(analyseDocEnum: AnalyzeDocEnum) {
    switch (analyseDocEnum) {
      case AnalyzeDocEnum.INTERVIEW:
        return 'Entretien';
      case AnalyzeDocEnum.EVENT:
        return 'Fiche de présence';
      default:
        return '';
    }
  }

  formatErrors(formControlName: string) {
    switch (formControlName) {
      case 'sex':
        return "Le sexe n'est pas renseigné";
      case 'email':
        return "L'email n'est pas renseigné ou n'a pas le bon format";
      case 'phone':
        return "Le téléphone n'est pas renseigné";
      case 'address':
        return "L'adresse n'est pas renseignée";
      case 'postCode':
        return "Le code postal n'est pas renseigné";
      case 'city':
        return "La ville n'est pas renseignée";
      case 'nameCenter':
        return "Le centre n'est pas renseigné";
      case 'responsable':
        return "Le responsable légal n'est pas renseigné";
      case 'programs':
        return 'Un programme doit être choisi';
      default:
        return '';
    }
  }

  formatDashboardCardTitle(dashboardType) {
    switch (dashboardType) {
      case DashboardType.REGISTERED_BY_ACTIVITIES:
        return 'Inscriptions par activité';
      case DashboardType.REGISTERED_BY_GENDER:
        return 'Inscrits par genre';
      case DashboardType.REGISTERED_BY_PROGRAMS:
        return 'Inscrits par programme';
      case DashboardType.AVERAGE_PRESENCE:
        return 'Moyenne des présences';
      case DashboardType.TRANCHE_SPORT_PRESENCE:
        return "Moyenne des présents par tranche d'âge / sport";
      default:
        return '';
    }
  }
}
