import {DatePipe} from '@angular/common';

export class FormatDateHelper {
  static formatDate(date: Date): string {
    const datePipe: DatePipe = new DatePipe('fr-FR');
    if (date) {
      return datePipe.transform(date, 'dd/MM/yyyy');
    } else {
      return '';
    }
  }

  static formatDateDetail(date: Date): string {
    const datePipe = new DatePipe('fr-FR');
    if (date) {
      return datePipe.transform(date, 'dd MMMM yyyy');
    } else {
      return '';
    }
  }

  static formatDateWithHour(date: Date): string {
    const datePipe = new DatePipe('fr-FR');
    if (date) {
      return datePipe.transform(date, 'dd/MM/yyyy HH:mm');
    } else {
      return '';
    }
  }

  static formatDateRange(dateBegin: Date, dateEnd: Date): string {
    if (dateBegin === dateEnd) {
      return `Le ${this.formatDateDetail(dateBegin)}`;
    } else {
      return `Du ${this.formatDateDetail(dateBegin)} au ${this.formatDateDetail(dateEnd)}`;
    }
  }

  static formatDateWeb(date: string): string {
    const datePipe = new DatePipe('fr-FR');
    if (date && date !== 'Invalid Date') {
      return datePipe.transform(date, 'yyyy-MM-dd');
    } else {
      return undefined;
    }
  }

  static formatLargeDate(date: Date): string {
    const datePipe = new DatePipe('fr-FR');
    if (date) {
      return datePipe.transform(date, 'fullDate');
    } else {
      return '';
    }
  }
}
