<form [formGroup]="formGroup" class="d-flex flex-column">
  <app-input-number [control]="formGroup.controls.minAgeGroup" [group]="formGroup" [required]="true" class="m-2"
                    fcName="minAgeGroup" textLabel="Tranche : age minimum" textLabel2="ans">
  </app-input-number>
  <app-input-number [control]="formGroup.controls.maxAgeGroup" [group]="formGroup" [required]="true" class="m-2"
                    fcName="maxAgeGroup" textLabel="Tranche : age maximum" textLabel2="ans">
  </app-input-number>
  <app-input-number [control]="formGroup.controls.minAgeSubGroup" [group]="formGroup" [required]="true"
                    class="m-2"
                    fcName="minAgeSubGroup" textLabel="Sous tranche : age minimum" textLabel2="ans">
  </app-input-number>
  <app-input-number [control]="formGroup.controls.maxAgeSubGroup" [group]="formGroup" [required]="true"
                    class="m-2"
                    fcName="maxAgeSubGroup" textLabel="Sous tranche : age maximum" textLabel2="ans">
  </app-input-number>

  <mat-checkbox class="px-3" color="primary" formControlName="isActive" labelPosition="before">Actif ?</mat-checkbox>

  <app-alert></app-alert>
  <div class="d-flex flex-row justify-content-end">
    <button (click)="no()" class="btn btn-secondary my-2 mx-2" type="button">Annuler</button>
    <button (click)="yes()" class="btn btn-primary my-2 mx-2" type="button">Enregistrer</button>
  </div>
</form>
