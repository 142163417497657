import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'sort',
  standalone: true
})
export class SortPipe implements PipeTransform {
  transform(array, ...fields: string[]) {
    if (!Array.isArray(array)) {
      return [];
    }
    return this.sortBy(array, fields);
  }

  sortBy(arr, keys, splitKeyChar = '~') {
    return arr.sort((i1, i2) => {
      const sortStr1 = keys.reduce((str, key) => {
        if (i1[key]) {
          return str + splitKeyChar + i1[key];
        } else {
          return str;
        }
      }, '');

      const sortStr2 = keys.reduce((str, key) => {
        if (i2[key]) {
          return str + splitKeyChar + i1[key];
        } else {
          return str;
        }
      }, '');
      return sortStr2.localeCompare(sortStr1);
    });
  }
}

export enum OrderEnum {
  ASC,
  DESC
}
