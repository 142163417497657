import {Component, Input, OnInit} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import {SelectOption} from '../../../../../model/form/select-option.model';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {Destroyed} from '../../../directives/destroyed.directive';
import {MatOptionModule} from '@angular/material/core';
import {AsyncPipe, NgClass, NgFor, NgIf} from '@angular/common';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

@Component({
  selector: 'app-data-list',
  templateUrl: './data-list.component.html',
  styleUrls: ['./data-list.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    NgClass,
    NgIf,
    NgFor,
    MatOptionModule,
    AsyncPipe
  ]
})
export class DataListComponent extends Destroyed implements OnInit {
  @Input() parentForm: UntypedFormGroup; // Family part
  @Input() i;
  @Input() control: UntypedFormControl;
  @Input() controlName: string;
  @Input() submitted: boolean;
  @Input() errorMsg: string;
  @Input() required = false;
  @Input() textLabel: string;
  @Input() dataObservable: Observable<SelectOption[]>;
  filteredData: Observable<string[]>;
  data: SelectOption[];

  constructor() {
    super();
  }

  ngOnInit() {
    this.data = new Array<SelectOption>();
    this.dataObservable.pipe(this.untilDestroyed()).subscribe((values: SelectOption[]) => {
      this.data = values;

      this.filteredData = this.control.valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value))
      );
    });

    if (this.parentForm.disabled) {
      this.control.disable();
    }
  }

  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return (this.data || [])
      .filter((data) => this._normalizeValue(data.name).includes(filterValue))
      .map((data) => data.name);
  }

  private _normalizeValue(value: string): string {
    return (value || '').toLowerCase().replace(/\s/g, '');
  }
}
