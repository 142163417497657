import {Injectable} from '@angular/core';
import {User} from '../model/user/user.model';
import {Module} from '../model/role/module.model';
import {RoleUser} from '../model/role/role-user.model';
import {ModuleSource} from '../model/enums/source/module-source.enum';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {
  currentUser: User;
  currentUserModule: Module[];
  public user: User;
  roleUser: RoleUser[];

  private readonly moduleByPathMap = {
    'profile-young': [
      ModuleSource.ADMINISTRATION,
      ModuleSource.ADMIN_NIV_1,
      ModuleSource.ADMIN_NIV_2,
      ModuleSource.VISUALISATION_UTILISATEUR,
      ModuleSource.MODIFICATION_UTILISATEUR,
      ModuleSource.VISUALISATION_VISITE_FAMILLE,
      ModuleSource.MODIFICATION_VISITE_FAMILLE,
      ModuleSource.VISUALISATION_DOCUMENT,
      ModuleSource.ADMINISTRATION_DOCUMENT
    ],
    'young-creation': [
      ModuleSource.ADMINISTRATION,
      ModuleSource.ADMIN_NIV_1,
      ModuleSource.ADMIN_NIV_2,
      ModuleSource.MODIFICATION_UTILISATEUR,
      ModuleSource.VISUALISATION_VISITE_FAMILLE,
      ModuleSource.MODIFICATION_VISITE_FAMILLE,
      ModuleSource.ADMINISTRATION_FINANCEMENT
    ],
    'young-validation/:id': [
      ModuleSource.ADMINISTRATION,
      ModuleSource.ADMIN_NIV_1,
      ModuleSource.ADMIN_NIV_2,
      ModuleSource.MODIFICATION_UTILISATEUR,
      ModuleSource.VISUALISATION_VISITE_FAMILLE,
      ModuleSource.MODIFICATION_VISITE_FAMILLE,
      ModuleSource.ADMINISTRATION_FINANCEMENT
    ],
    'young-info/:id': [
      ModuleSource.ADMINISTRATION,
      ModuleSource.ADMIN_NIV_1,
      ModuleSource.ADMIN_NIV_2,
      ModuleSource.VISUALISATION_UTILISATEUR,
      ModuleSource.MODIFICATION_UTILISATEUR,
      ModuleSource.VISUALISATION_VISITE_FAMILLE,
      ModuleSource.MODIFICATION_VISITE_FAMILLE,
      ModuleSource.ADMINISTRATION_FINANCEMENT,
      ModuleSource.VISUALISATION_DOCUMENT,
      ModuleSource.ADMINISTRATION_DOCUMENT
    ],
    'my-events': [
      ModuleSource.ADMINISTRATION,
      ModuleSource.ADMIN_NIV_1,
      ModuleSource.ADMIN_NIV_2,
      ModuleSource.VISUALISATION_DOCUMENT,
      ModuleSource.ADMINISTRATION_DOCUMENT,
      ModuleSource.VISUALISATION_EVENEMENT,
      ModuleSource.MODIFICATION_EVENEMENT
    ],
    'list-jeune-by-one-activite/:id': [
      ModuleSource.ADMINISTRATION,
      ModuleSource.ADMIN_NIV_1,
      ModuleSource.ADMIN_NIV_2,
      ModuleSource.VISUALISATION_DOCUMENT,
      ModuleSource.ADMINISTRATION_DOCUMENT,
      ModuleSource.VISUALISATION_EVENEMENT,
      ModuleSource.MODIFICATION_EVENEMENT
    ],
    'event-detail/:id': [
      ModuleSource.ADMINISTRATION,
      ModuleSource.ADMIN_NIV_1,
      ModuleSource.ADMIN_NIV_2,
      ModuleSource.VISUALISATION_DOCUMENT,
      ModuleSource.ADMINISTRATION_DOCUMENT,
      ModuleSource.VISUALISATION_EVENEMENT,
      ModuleSource.MODIFICATION_EVENEMENT
    ],
    'young-presence/:id': [
      ModuleSource.ADMINISTRATION,
      ModuleSource.ADMIN_NIV_1,
      ModuleSource.ADMIN_NIV_2,
      ModuleSource.VISUALISATION_DOCUMENT,
      ModuleSource.ADMINISTRATION_DOCUMENT,
      ModuleSource.VISUALISATION_EVENEMENT,
      ModuleSource.MODIFICATION_EVENEMENT
    ],
    'search-young/:id': [
      ModuleSource.ADMINISTRATION,
      ModuleSource.ADMIN_NIV_1,
      ModuleSource.ADMIN_NIV_2,
      ModuleSource.MODIFICATION_EVENEMENT
    ],
    'event-creation': [
      ModuleSource.ADMINISTRATION,
      ModuleSource.ADMIN_NIV_1,
      ModuleSource.ADMIN_NIV_2,
      ModuleSource.MODIFICATION_EVENEMENT
    ],
    'ensemble-detail/:id': [
      ModuleSource.ADMINISTRATION,
      ModuleSource.ADMIN_NIV_1,
      ModuleSource.ADMIN_NIV_2,
      ModuleSource.VISUALISATION_EVENEMENT,
      ModuleSource.MODIFICATION_EVENEMENT
    ],
    'list-jeune-by-activite/:id': [
      ModuleSource.ADMINISTRATION,
      ModuleSource.ADMIN_NIV_1,
      ModuleSource.ADMIN_NIV_2,
      ModuleSource.VISUALISATION_EVENEMENT,
      ModuleSource.MODIFICATION_EVENEMENT
    ],
    'occurrence-creation': [ModuleSource.ADMINISTRATION, ModuleSource.ADMIN_NIV_2],
    export: [
      ModuleSource.ADMINISTRATION,
      ModuleSource.ADMIN_NIV_1,
      ModuleSource.ADMIN_NIV_2,
      ModuleSource.VISUALISATION_EVENEMENT,
      ModuleSource.MODIFICATION_EVENEMENT,
      ModuleSource.VISUALISATION_UTILISATEUR,
      ModuleSource.MODIFICATION_UTILISATEUR
    ],
    import: [
      ModuleSource.ADMINISTRATION,
      ModuleSource.ADMIN_NIV_2,
      ModuleSource.ADMINISTRATION_FINANCEMENT
    ],
    'young-fusion': [
      ModuleSource.ADMINISTRATION,
      ModuleSource.ADMIN_NIV_2,
      ModuleSource.ADMIN_NIV_1
    ],
    family: [
      ModuleSource.ADMINISTRATION,
      ModuleSource.ADMIN_NIV_2,
      ModuleSource.ADMIN_NIV_1,
      ModuleSource.VISUALISATION_UTILISATEUR,
      ModuleSource.MODIFICATION_UTILISATEUR,
      ModuleSource.VISUALISATION_VISITE_FAMILLE,
      ModuleSource.MODIFICATION_VISITE_FAMILLE
    ],
    'family-setting': [
      ModuleSource.ADMINISTRATION,
      ModuleSource.ADMIN_NIV_2,
      ModuleSource.ADMIN_NIV_1,
      ModuleSource.VISUALISATION_UTILISATEUR,
      ModuleSource.MODIFICATION_UTILISATEUR,
      ModuleSource.VISUALISATION_VISITE_FAMILLE,
      ModuleSource.MODIFICATION_VISITE_FAMILLE
    ],
    'family-setting-mobile': [
      ModuleSource.ADMINISTRATION,
      ModuleSource.ADMIN_NIV_2,
      ModuleSource.ADMIN_NIV_1,
      ModuleSource.VISUALISATION_UTILISATEUR,
      ModuleSource.MODIFICATION_UTILISATEUR,
      ModuleSource.VISUALISATION_VISITE_FAMILLE,
      ModuleSource.MODIFICATION_VISITE_FAMILLE
    ],
    'family-setting/visit-family/:id': [
      ModuleSource.ADMINISTRATION,
      ModuleSource.ADMIN_NIV_2,
      ModuleSource.ADMIN_NIV_1,
      ModuleSource.MODIFICATION_VISITE_FAMILLE
    ],
    'family-setting-mobile/visit-family/:id': [
      ModuleSource.ADMINISTRATION,
      ModuleSource.ADMIN_NIV_2,
      ModuleSource.ADMIN_NIV_1,
      ModuleSource.MODIFICATION_VISITE_FAMILLE
    ],
    speaker: [
      ModuleSource.ADMINISTRATION,
      ModuleSource.ADMIN_NIV_2,
      ModuleSource.ADMIN_NIV_1,
      ModuleSource.MODIFICATION_INTERVENANT,
      ModuleSource.VISUALISATION_INTERVENANT_STRUCTURE
    ],
    'speaker/:id': [
      ModuleSource.ADMINISTRATION,
      ModuleSource.ADMIN_NIV_2,
      ModuleSource.ADMIN_NIV_1,
      ModuleSource.MODIFICATION_INTERVENANT,
      ModuleSource.VISUALISATION_INTERVENANT_STRUCTURE
    ],
    'speaker/add': [
      ModuleSource.ADMINISTRATION,
      ModuleSource.ADMIN_NIV_2,
      ModuleSource.ADMIN_NIV_1,
      ModuleSource.MODIFICATION_INTERVENANT
    ],
    'speaker/edit/:id': [
      ModuleSource.ADMINISTRATION,
      ModuleSource.ADMIN_NIV_2,
      ModuleSource.ADMIN_NIV_1,
      ModuleSource.MODIFICATION_INTERVENANT
    ],
    structures: [
      ModuleSource.ADMINISTRATION,
      ModuleSource.ADMIN_NIV_2,
      ModuleSource.ADMIN_NIV_1,
      ModuleSource.MODIFICATION_STRUCTURE,
      ModuleSource.VISUALISATION_INTERVENANT_STRUCTURE
    ],
    'structures/detail/:id': [
      ModuleSource.ADMINISTRATION,
      ModuleSource.ADMIN_NIV_2,
      ModuleSource.ADMIN_NIV_1,
      ModuleSource.MODIFICATION_STRUCTURE,
      ModuleSource.VISUALISATION_INTERVENANT_STRUCTURE
    ],
    'structures/add': [
      ModuleSource.ADMINISTRATION,
      ModuleSource.ADMIN_NIV_2,
      ModuleSource.ADMIN_NIV_1,
      ModuleSource.MODIFICATION_STRUCTURE
    ],
    'structures/edit/:id': [
      ModuleSource.ADMINISTRATION,
      ModuleSource.ADMIN_NIV_2,
      ModuleSource.ADMIN_NIV_1,
      ModuleSource.MODIFICATION_STRUCTURE
    ],
    reporting: [ModuleSource.ADMINISTRATION, ModuleSource.REPORTING],
    admin: [ModuleSource.ADMINISTRATION],
    'role-users': [ModuleSource.ADMINISTRATION],
    'role-setting': [ModuleSource.ADMINISTRATION],
    referentiel: [ModuleSource.ADMINISTRATION],
    'file-manager': [ModuleSource.ADMINISTRATION],
    'envoi-mail': [ModuleSource.ADMINISTRATION],
    'place-setting': [ModuleSource.ADMINISTRATION],
    'session-setting': [ModuleSource.ADMINISTRATION],
    'profile-creation': [ModuleSource.ADMINISTRATION],
    'profile-setting': [ModuleSource.ADMINISTRATION],
    'profile-detail/:id': [ModuleSource.ADMINISTRATION],
    'user-setting': [ModuleSource.ADMINISTRATION],
    'depot-document': [
      ModuleSource.ADMINISTRATION,
      ModuleSource.ADMINISTRATION_FINANCEMENT,
      ModuleSource.ADMINISTRATION_DOCUMENT
    ]
  };

  hasModule(module: ModuleSource) {
    return this.currentUserModule.some((mod) => mod.code === ModuleSource[module]);
  }

  canAccess(path: string): boolean {
    if (path === 'dashboard' || path === 'dashboard-mobile') {
      return true;
    }

    const moduleSource = this.moduleByPathMap[path];
    if (moduleSource) {
      return this.checkModule(...moduleSource);
    }

    return false;
  }

  checkModule(...moduleSource: ModuleSource[]): boolean {
    if (this.currentUserModule && this.currentUserModule.length > 0) {
      if (this.hasModule(ModuleSource.ADMINISTRATION)) {
        return true;
      }
      const moduleCodes = moduleSource.map((item) => ModuleSource[item]);
      return this.currentUserModule.some((module) => moduleCodes.includes(module.code));
    }
    return false;
  }
}
