import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {Session} from 'src/app/model/event/session.model';

@Injectable({
  providedIn: 'root'
})
export class SessionWebservice {
  private readonly root = `${environment.envApiUrl}/sessions`;

  constructor(private readonly http: HttpClient) {}

  getSessionById(idSession: number) {
    return this.http.get(`${this.root}/${idSession}`);
  }

  getAllSessions() {
    return this.http.get(`${this.root}`);
  }

  getSessionTreeData(isActive: boolean) {
    return this.http.get(`${this.root}/tree/${isActive}`);
  }

  postAddSession(session: Session) {
    return this.http.post(`${this.root}/`, session);
  }

  putUpdateSession(session: Session) {
    return this.http.put(`${this.root}/update`, session);
  }

  deleteSession(idSession: number) {
    return this.http.delete(`${this.root}/${idSession}`);
  }
}
