import {Component, Input, OnInit} from '@angular/core';
import {Alert} from 'src/app/model/alert.model';
import {AlertTypeEnum} from 'src/app/model/enums/alert-type.enum';
import {AlertService} from 'src/app/services/alert.service';
import {Destroyed} from '../../directives/destroyed.directive';
import {NgFor} from '@angular/common';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  standalone: true,
  imports: [NgFor]
})
export class AlertComponent extends Destroyed implements OnInit {
  @Input() id?: string;

  alerts: Alert[] = [];

  constructor(private readonly alertService: AlertService) {
    super();
  }

  ngOnInit() {
    this.alertService
      .onAlert(this.id)
      .pipe(this.untilDestroyed())
      .subscribe((alert) => {
        if (!alert.message) {
          // clear alerts when an empty alert is received
          this.alerts = [];
          return;
        }

        // add alert to array
        this.alerts.push(alert);
      });
  }

  decrementAlertTime(timeLeft, alert, intervalId) {
    if (timeLeft === 0) {
      this.removeAlert(alert);
      clearInterval(intervalId);
    }
  }

  removeAlert(alert: Alert) {
    // remove specified alert from array
    this.alerts = this.alerts.filter((x) => x !== alert);
  }

  cssClass(alert: Alert) {
    if (!alert) {
      return '';
    }
    // return css class based on alert type
    switch (alert.type) {
      case AlertTypeEnum.SUCCESS:
        return 'alert alert-success';
      case AlertTypeEnum.ERROR:
        return 'alert alert-danger';
      case AlertTypeEnum.INFO:
        return 'alert alert-info';
      case AlertTypeEnum.WARNING:
        return 'alert alert-warning';
      default:
        return '';
    }
  }
}
