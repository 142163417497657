import {HttpParams} from '@angular/common/http';

export class PageRequest {
  pageNumber: number;
  pageSize: number;

  constructor(pageNumber: number, pageSize: number) {
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
  }
}

export namespace PageRequest {
  export function toQueryParams(
    pageRequest: PageRequest,
    ...supplementaryParams: {key: string; value: string | number | boolean}[]
  ) {
    let params = new HttpParams()
      .append('pageSize', pageRequest.pageSize)
      .append('pageNumber', pageRequest.pageNumber);

    if (supplementaryParams) {
      supplementaryParams.forEach((suppParam) => {
        params = params.append(suppParam.key, suppParam.value);
      });
      return {params};
    }

    return {
      params
    };
  }
}
