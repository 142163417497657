import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {RelationBinome} from '../../model/user/relation-binome.model';

@Injectable({
  providedIn: 'root'
})
export class RelationBinomeWebservice {
  private readonly root = `${environment.envApiUrl}/relationBinome`;

  constructor(private readonly http: HttpClient) {}

  getRelationBinomeById(id: number) {
    return this.http.get(`${this.root}/${id}`);
  }

  updateRelationBinome(relationBinome: RelationBinome) {
    return this.http.put(`${this.root}`, relationBinome);
  }

  postRelationBinome(relationBinome: RelationBinome) {
    return this.http.post(`${this.root}`, relationBinome);
  }

  deleteRelationBinome(id: number) {
    return this.http.delete(`${this.root}/${id}`);
  }
}
