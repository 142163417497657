<div [formGroup]="parentForm" class="form-group">
  <label [for]="controlName+i" class="label-info">{{textLabel}}</label>
  <input #inputDataList
         [attr.list]="controlName+'data'+i"
         [formControl]="control"
         [id]="controlName+i"
         [matAutocomplete]="auto"
         [ngClass]="{'is-invalid': (control.dirty || control.touched || submitted) && control.invalid && submitted}"
         class="custom-select custom-select-sm"
         style="padding: 1.1rem;">
  <div *ngIf="control.invalid && submitted" class="invalid-feedback">
    <div *ngIf="control.errors.required">{{errorMsg}}</div>
  </div>
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let item of filteredData | async" value="{{item}}">
      {{item}}
    </mat-option>
  </mat-autocomplete>
</div>
