<div class="item-per-page" *ngIf="showItemPerPage || showHugeItemPerPage">
  <span class="text-paginate">Par page </span>
  <select class="item-per-page-select" [(ngModel)]="itemPerPage" (ngModelChange)="changeItemPerPage()">
    <option [ngValue]="10">10</option>
    <option [ngValue]="20">20</option>
    <option [ngValue]="50">50</option>
    <option [ngValue]="100">100</option>
    @if(showHugeItemPerPage) {
    <option [ngValue]="500">500</option>
    <option [ngValue]="1000">1000</option>
    }
  </select>
</div>

<mat-icon (click)="goBegin()" class="pointer">skip_previous</mat-icon>
<mat-icon (click)="goPrevious()" class="pointer">navigate_before</mat-icon>

<span class="text-paginate">Page {{paginationService.page}} / {{paginationService.pageMax}} </span>

<mat-icon (click)="goNext()" class="pointer">navigate_next</mat-icon>
<mat-icon (click)="goEnd()" class="pointer">skip_next</mat-icon>

<div class="counter">
  <span class="text-paginate"> {{paginationService.count > 0 ? paginationService.nbElemMin : 0}} à {{paginationService.nbElemMax}} sur {{paginationService.count}} </span>
</div>


