import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl
} from '@angular/forms';
import {NgClass} from '@angular/common';

@Component({
  selector: 'app-select-number',
  templateUrl: './select-number.component.html',
  styleUrls: ['./select-number.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgClass]
})
export class SelectNumberComponent {
  @Input() control: AbstractControl; // FormControl
  @Input() fcName: string; // Nom du FormControl
  @Input() textLabel: string; // Label
  @Input() group; // Group contenant le FormControle
  @Input() i = 0; // i si l'input est dans une boucle (pour éviter d'avoir 2 composants ayant le même id

  @Input() min;
  @Input() max;

  @Input() modelAttribute: number; // Valeur entrée
  @Output() modelAttributeChange = new EventEmitter(); // Valeur envoyée au parent

  change(newValue) {
    this.modelAttribute = newValue;
    this.modelAttributeChange.emit(newValue);
  }

  changeValueMinus() {
    if (!this.min) {
      this.changeValueIfHasNotMin();
    } else {
      this.changeValueIfHasMin();
    }
  }

  changeValueIfHasNotMin() {
    if (!(this.control as UntypedFormControl).disabled) {
      if (this.control.value === 0 || !this.control.value) {
        this.control.setValue(0);
      } else {
        this.control.setValue(this.control.value - 1);
      }
    }
  }

  changeValueIfHasMin() {
    if (!(this.control as UntypedFormControl).disabled) {
      if (this.control.value === 0 || !this.control.value) {
        this.control.setValue(0);
      } else {
        if (this.control.value > this.min) {
          this.control.setValue(this.control.value - 1);
        }
      }
    }
  }

  changeValuePlus() {
    if (!this.max) {
      this.changeValueIfHasNotMax();
    } else {
      this.changeValueIfHasMax();
    }
  }

  changeValueIfHasMax() {
    if (!(this.control as UntypedFormControl).disabled) {
      if (!this.control.value) {
        this.control.setValue(1);
      } else {
        if (this.control.value < this.max) {
          this.control.setValue(this.control.value + 1);
        }
      }
    }
  }

  changeValueIfHasNotMax() {
    if (!(this.control as UntypedFormControl).disabled) {
      if (!this.control.value) {
        this.control.setValue(1);
      } else {
        this.control.setValue(this.control.value + 1);
      }
    }
  }

  resetNumber($event) {
    const value = $event.target.value;
    if (this.min > 0 && value < this.min) {
      this.control.setValue(this.min);
    }

    if (this.max && value > this.max) {
      this.control.setValue(this.max);
    }
  }
}
