export enum ModuleSource {
  VISUALISATION_UTILISATEUR = 1,
  MODIFICATION_UTILISATEUR,
  ADMINISTRATION_FINANCEMENT,
  VISUALISATION_DOCUMENT,
  ADMINISTRATION_DOCUMENT,
  VISUALISATION_EVENEMENT,
  MODIFICATION_EVENEMENT,
  VISUALISATION_INTERVENANT_STRUCTURE,
  MODIFICATION_INTERVENANT,
  MODIFICATION_STRUCTURE,
  VISUALISATION_VISITE_FAMILLE,
  MODIFICATION_VISITE_FAMILLE,
  ADMINISTRATION,
  ADMIN_NIV_1,
  ADMIN_NIV_2,
  REPORTING,
  VISUALISATION_APP_MOBILE
}

export const onlyAdmin = [
  ModuleSource.ADMINISTRATION,
  ModuleSource.ADMIN_NIV_1,
  ModuleSource.ADMIN_NIV_2
];

export const superAdmin = [ModuleSource.ADMINISTRATION, ModuleSource.ADMIN_NIV_2];
