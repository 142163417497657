<div class="d-flex flex-column fixed-size">
  <mat-dialog-content class="flex-fill">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <!-- This is the tree node template for leaf nodes -->
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
        <button disabled mat-icon-button></button>
        <mat-checkbox (change)="selectNode(node)" [checked]="checkNode(node)" class="checklist-leaf-node"
                      color="primary">{{node.name}}</mat-checkbox>
      </mat-tree-node>
      <!-- This is the tree node template for expandable nodes -->
      <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
        <button [attr.aria-label]="'toggle ' + node.name" mat-icon-button
                matTreeNodeToggle>
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <mat-checkbox (change)="selectNode(node)" *ngIf="canCheckAll" [checked]="checkNode(node)" class="checklist-leaf-node"
                      color="primary">{{node.name}}</mat-checkbox>
        <p *ngIf="!canCheckAll">{{node.name}}</p>
      </mat-tree-node>
    </mat-tree>
  </mat-dialog-content>

  <mat-dialog-actions class="m-0 d-flex flex-row justify-content-center">
    <button (click)="onNoClick()" class="mx-4 col-4 btn btn-secondary" mat-button>Annuler</button>
    <button (click)="onValidation()" class="mx-4 col-4 btn btn-primary" mat-button>Valider</button>
  </mat-dialog-actions>
</div>
