import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CenterDTO} from '../../model/dto/center.dto';
import {PlaceDTO} from '../../model/dto/place.dto';
import {Place} from 'src/app/model/event/place/place.model';

@Injectable({
  providedIn: 'root'
})
export class PlaceWebservice {
  private readonly root = `${environment.envApiUrl}/places`;

  constructor(private readonly http: HttpClient) {}

  getPlaceById(idPlace: number) {
    return this.http.get(`${this.root}/${idPlace}`);
  }

  getAllPlaces(): Observable<PlaceDTO[]> {
    return this.http.get(`${this.root}`).pipe(map((value) => value as PlaceDTO[]));
  }

  getAllCenters(): Observable<Array<CenterDTO>> {
    return this.http.get(`${this.root}/centers`).pipe(map((value) => value as Array<CenterDTO>));
  }

  getYoungByCenter(idEvent: number, idPlace: number): Observable<number> {
    return this.http
      .get(`${this.root}/youngs/${idEvent}/${idPlace}`)
      .pipe(map((value) => value as number));
  }

  postAddPlace(place: Place) {
    return this.http.post(`${this.root}`, place);
  }

  deletePlace(idPlace: number) {
    return this.http.delete(`${this.root}/${idPlace}`);
  }

  putUpdatePlace(place: Place) {
    return this.http.put(`${this.root}/update`, place);
  }

  getTerritories(): Observable<Place[]> {
    return this.http.get<Place[]>(`${this.root}/territories`);
  }
}
