@if (treeControl && dataSource?.data) {
  <mat-dialog-content class="d-flex flex-column fixed-size">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node" [disabled]="node.disabled" matTreeNodePadding matTreeNodeToggle>
        <button disabled mat-icon-button></button>
        <mat-checkbox (change)="todoLeafItemSelectionToggle(node)" [checked]="checklistSelection.isSelected(node)" [disabled]="node.disabled"
                      class="checklist-leaf-node" color="primary">{{node.name}}</mat-checkbox>
      </mat-tree-node>

      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" [disabled]="node.disabled" matTreeNodePadding>
        <button [attr.aria-label]="'toggle ' + node.filename" [disabled]="node.disabled" mat-icon-button
                matTreeNodeToggle>
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <mat-checkbox (change)="todoItemSelectionToggle(node)" [checked]="descendantsAllSelected(node)"
                      [disabled]="node.disabled"
                      [indeterminate]="descendantsPartiallySelected(node)" color="primary">{{node.name}}</mat-checkbox>
      </mat-tree-node>
    </mat-tree>
  </mat-dialog-content>

  <mat-dialog-actions class="m-0 d-flex flex-row justify-content-center">
    <button (click)="onNoClick()" class="mx-4 col-4 btn btn-secondary" mat-button>Annuler</button>
    <button (click)="onValidation()" class="mx-4 col-4 btn btn-primary" mat-button>Valider</button>
  </mat-dialog-actions>
}



