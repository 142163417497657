<form [formGroup]="formGroup" class="d-flex flex-column">
  <app-input-text [control]="formGroup.controls.name" [group]="formGroup" class="px-3" fcName="name" textLabel="Nom :">
  </app-input-text>
  <app-input-text [control]="formGroup.controls.code" [group]="formGroup" class="px-3" fcName="code" textLabel="Code :">
  </app-input-text>
  <mat-checkbox class="px-3" color="primary" formControlName="isActive" labelPosition="before">Actif ?</mat-checkbox>

  <app-alert></app-alert>
  <div class="d-flex flex-row justify-content-end">
    <button (click)="no()" class="btn btn-secondary my-2 mx-2" type="button">Annuler</button>
    <button (click)="yes()" class="btn btn-primary my-2 mx-2" type="button">Enregistrer le lieu</button>
  </div>
</form>
