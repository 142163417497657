import {Component, Input} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule]
})
export class RadioButtonComponent {
  @Input() group; // Group contenant le FormControle
  @Input() fcName: string; // Nom du FormControl
  @Input() textLabel: string; // Label
  @Input() valueRadioBtn;
  @Input() idRadioBtn;
}
