import {Component, OnInit} from '@angular/core';
import {TreeItemNode} from 'src/app/model/event/treeData/tree-item-node.model';
import {TreeItemFlatNode} from 'src/app/model/event/treeData/tree-item-flat-node.model';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatDialogActions, MatDialogContent, MatDialogRef} from '@angular/material/dialog';
import {MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule} from '@angular/material/tree';
import {TreeDataService} from 'src/app/services/tree-data.service';
import {Destroyed} from '../../../directives/destroyed.directive';
import {NgIf} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'app-select-tree',
  templateUrl: './select-tree.component.html',
  styleUrls: ['./select-tree.component.scss'],
  standalone: true,
  imports: [
    MatDialogContent,
    MatTreeModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    NgIf,
    MatDialogActions
  ]
})
export class SelectTreeComponent extends Destroyed implements OnInit {
  selectedNode: TreeItemFlatNode;
  treeControl = new FlatTreeControl<TreeItemFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );
  treeFlattener: MatTreeFlattener<TreeItemNode, TreeItemFlatNode>;
  dataSource: MatTreeFlatDataSource<TreeItemNode, TreeItemFlatNode>;
  canCheckAll = false;
  isPlaceTree = false;

  constructor(
    private readonly treeDataService: TreeDataService,
    public dialogRef: MatDialogRef<SelectTreeComponent>
  ) {
    super();
    this.treeFlattener = new MatTreeFlattener(
      this._transformer,
      (node) => node.level,
      (node) => node.expandable,
      (node) => node.listOfChilds
    );
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.treeDataService.allPlacesMessager.pipe(this.untilDestroyed()).subscribe((treeOfPlace) => {
      this.dataSource.data = treeOfPlace;
    });
  }

  ngOnInit() {
    this.treeDataService.allExcludePlacesMessager
      .pipe(this.untilDestroyed())
      .subscribe((listOfExclude) => {
        if (listOfExclude && listOfExclude.length > 0) {
          const baseList = this.dataSource.data;
          baseList.forEach((item) => {
            this.checkChild(item, listOfExclude);
          });
          this.dataSource.data = baseList;
        }
      });

    if (this.isPlaceTree === true) {
      this.treeControl.dataNodes.forEach((node) => {
        if (node.level === 0) {
          this.treeControl.expand(node);
        }
      });
    }
  }

  hasChild = (_: number, node: TreeItemFlatNode) => node.expandable;

  onNoClick(): void {
    this.dialogRef.close();
  }

  onValidation() {
    this.dialogRef.close(this.selectedNode);
  }

  checkNode(node: TreeItemFlatNode) {
    return node === this.selectedNode;
  }

  selectNode(node: TreeItemFlatNode) {
    if (this.selectedNode === node) {
      this.selectedNode = undefined;
    } else {
      this.selectedNode = node;
    }
  }

  private readonly _transformer = (node: TreeItemNode, level: number): TreeItemFlatNode => {
    return {
      id: node.id,
      expandable: !!node.listOfChilds && node.listOfChilds.length > 0,
      name: node.name,
      level
    };
  };

  private checkChild(place: TreeItemNode, listOfExclude: TreeItemNode[]) {
    if (place && place.listOfChilds && listOfExclude) {
      place.listOfChilds = place.listOfChilds.filter(
        (item) => !listOfExclude.find((excludeItem) => excludeItem.id === item.id)
      );
      place.listOfChilds.forEach((child) => {
        this.checkChild(child, listOfExclude);
      });
    }
  }
}
