import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'programName',
  standalone: true
})
export class ProgramNamePipe implements PipeTransform {
  transform(programName: string): string {
    switch (programName) {
      case 'Sport':
        return 'Sport dans la Ville';
      case 'JDLV':
        return 'Job dans la Ville';
      case 'LDLV':
        return 'L dans la Ville';
      case 'EDLV':
        return 'Entrepreneurs dans la Ville';
      default:
        return '???';
    }
  }
}
