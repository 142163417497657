import {Injectable} from '@angular/core';
import {User} from '../../model/user/user.model';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {UserCheckingDTO} from '../../model/dto/user-checking.dto';
import {HttpBackend, HttpClient, HttpParams} from '@angular/common/http';
import {CenterDTO} from '../../model/dto/center.dto';
import {map} from 'rxjs/operators';
import {UserProfileDTO} from 'src/app/model/dto/user-profile.dto';
import {UserFamilyDTO} from 'src/app/model/dto/user-family.dto';
import {RegisterErrorDTO} from 'src/app/model/dto/register-error.dto';
import {FamilySettingDTO} from '../../model/dto/family-setting.dto';
import {IntervenantTypeEnum} from '../../model/enums/intervenant-type.enum';
import {FilterUser} from '../../model/filter/filter-relation-binome/filter-user.model';
import {FusionUserDTO} from '../../model/dto/fusion-user.dto';
import {UserSearchRequest} from '../../model/request/user-search-request.model';
import {Paged} from '../../model/response/paged.model';
import {PageRequest} from 'src/app/model/request/pageRequest';
import {UserSearch} from '../../model/user/user-search.model';
import {FamilySettingRequest} from '../../model/request/family-setting-request.model';
import {FilterRelationBinomeIntervenantDTO} from '../../model/filter/filter-relation-binome/filter-relation-binome-intervenant.dto';
import {UserIntervenantDto} from 'src/app/model/dto/user-intervenant.dto';
import {getMobileToken} from 'src/app/utils/utils.static';

@Injectable({
  providedIn: 'root'
})
export class UserWebservice {
  private readonly root = `${environment.envApiUrl}/users`;

  private readonly httpMobile: HttpClient;

  constructor(private readonly http: HttpClient,
              private readonly httpBackend: HttpBackend) {
    this.httpMobile = new HttpClient(httpBackend);
  }

  getUserById(idUser: number) {
    return this.http.get(`${this.root}/${idUser}`);
  }

  refreshCurrentUser() {
    const token = getMobileToken();
    // Obtient l'user actuellement connecté (grâce au token (Firebase ou Azure))
    if (token) {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      return this.httpMobile.get(`${this.root}`, options);
    } else {
      return this.http.get(`${this.root}`);
    }

  }

  getFamilies(
    searchRequest: FamilySettingRequest,
    pageRequest: PageRequest
  ): Observable<Paged<FamilySettingDTO>> {
    return this.http.post<Paged<FamilySettingDTO>>(
      `${this.root}/families/all`,
      searchRequest,
      PageRequest.toQueryParams(pageRequest)
    );
  }

  getFamilyForUser(id: number): Observable<UserFamilyDTO[]> {
    const token = getMobileToken();
    if (token) {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      return this.httpMobile.get<UserFamilyDTO[]>(`${this.root}/families/${id}`, options);
    } else {
      return this.http.get<UserFamilyDTO[]>(`${this.root}/families/${id}`);
    }
  }

  postRegisterUser(dataUser: User) {
    // Inscription jeune: inscrit un nouveau jeune
    return this.http.post(`${this.root}/register/complete`, dataUser);
  }

  postSmsValidation(user: User, isJob: boolean = false) {
    // Sms pour les jeunes web qui sont validés
    return this.http.post(`${this.root}/validation/sms?isJob=${isJob}`, user);
  }

  // Check si un user existe déjà grâce à son nom/prénom/date de naissance, renvoie un jeune ou 404
  postCheckUserExist(dataUser: UserCheckingDTO) {
    return this.http.post(`${this.root}/checking`, dataUser);
  }

  // Check si un user existe déjà grâce à son id, renvoie un jeune ou 404
  getCheckUserExistById(idUser: number) {
    return this.http.get(`${this.root}/checking/${idUser}`);
  }

  getOldPlaceByUserId(idUser: number) {
    return this.http.get(`${this.root}/lastplace/${idUser}`);
  }

  postUserFamilySetting(family: UserFamilyDTO): Observable<RegisterErrorDTO> {
    return this.http.post<RegisterErrorDTO>(`${this.root}/family/add`, family);
  }

  postDeleteUserFamilySetting(family: UserFamilyDTO): Observable<RegisterErrorDTO> {
    return this.http.post<RegisterErrorDTO>(`${this.root}/family/remove`, family);
  }

  getAllCenters(): Observable<Array<CenterDTO>> {
    return this.http.get(`${this.root}/centers`).pipe(map((value) => value as Array<CenterDTO>));
  }

  getAllUserProfils(justUserSDLV: boolean = undefined, pageNumber?: number, pageSize?: number) {
    let params = new HttpParams();
    if (justUserSDLV !== undefined) {
      params = params.set('userSDLV', justUserSDLV ? 'true' : 'false');
    }
    if (pageNumber !== undefined) {
      params = params.set('pageNumber', pageNumber.toString());
    }
    if (pageSize !== undefined) {
      params = params.set('pageSize', pageSize.toString());
    }

    return this.http.get(`${this.root}/profiles`, {params});
  }

  getUserSDLVAutocomplete(name?: string) {
    let params = new HttpParams();
    params = params.set('name', name ?? '');
    return this.http.get(`${this.root}/user-sdlv-autocomplete`, {params});
  }

  getProfileByIdUser(idUser: number) {
    return this.http.get(`${this.root}/profiles/${idUser}`);
  }

  getTutorByYear(year: number) {
    return this.http.get(`${this.root}/tutor/year/${year}`);
  }

  postCreateProfile(profile: UserProfileDTO) {
    return this.http.post(`${this.root}/profiles`, profile);
  }

  postCheckMailExist(email: string, id: number = null) {
    return this.http.post(`${this.root}/check/mail?userid=${id ? id : 0}`, {content: email});
  }

  postCheckPhoneExist(phone: string, id: number = null) {
    return this.http.post(`${this.root}/check/phone?userid=${id ? id : 0}`, {content: phone});
  }

  putUpdateProfile(listOfProfiles: UserProfileDTO[]) {
    return this.http.put(`${this.root}/profiles`, listOfProfiles);
  }

  checkUserExistByMail(email: string) {
    return this.http.get(`${this.root}/check?email=${email}`);
  }

  deleteUser(idUser: number, isTotalSuppression: boolean) {
    return this.http.delete(`${this.root}/${idUser}/${isTotalSuppression}`);
  }

  fusion(fusionUserDto: FusionUserDTO) {
    return this.http.post(`${this.root}/fusion`, fusionUserDto);
  }

  findByPhone(phone: string): Observable<User[]> {
    return this.http.post<User[]>(`${this.root}/phone`, {content: phone});
  }

  findByMail(mail: string): Observable<User[]> {
    return this.http.post<User[]>(`${this.root}/mail`, {content: mail});
  }

  deleteTutor(listOfIdUser: number[]) {
    return this.http.post(`${this.root}/tutor/delete`, listOfIdUser);
  }

  isValid(idUser: number) {
    return this.http.get(`${this.root}/isValid/${idUser}`);
  }

  getAllRiFromPlace(idPlace: number) {
    return this.http.get(`${this.root}/ri/${idPlace}`);
  }

  getAllRi() {
    return this.http.get(`${this.root}/ri`);
  }

  getAllVisitors() {
    const token = getMobileToken();
    if (token) {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      return this.httpMobile.get(`${this.root}/visitor/all`, options);
    } else {
      return this.http.get(`${this.root}/visitor/all`);
    }
  }

  getAllVisitorsActif() {
    const token = getMobileToken();
    if (token) {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      return this.httpMobile.get(`${this.root}/visitor/actif`, options);
    } else {
      return this.http.get(`${this.root}/visitor/actif`);
    }
  }

  getAllIntervenant(
    intervenantType: IntervenantTypeEnum,
    filter: FilterUser
  ): Observable<Paged<User>> {
    return this.http.post<Paged<User>>(`${this.root}/intervenant/${intervenantType}`, filter);
  }

  getIntervenantEventAutocomplete(filter: FilterUser): Observable<Paged<UserIntervenantDto>> {
    return this.http.post<Paged<UserIntervenantDto>>(`${this.root}/intervenant-event`, filter);
  }

  getYoungByRi(idEvent: number, idRi: number): Observable<number> {
    return this.http
      .get(`${this.root}/youngs/${idEvent}/${idRi}`)
      .pipe(map((value) => value as number));
  }

  getAllUserSDLVByName(valueToSend: FilterUser): Observable<FilterRelationBinomeIntervenantDTO[]> {
    return this.http.post<FilterRelationBinomeIntervenantDTO[]>(
      `${this.root}/sdlvName/`,
      valueToSend
    );
  }

  searchInAllUsers(
    request: UserSearchRequest,
    pageRequest: PageRequest
  ): Observable<Paged<UserSearch>> {
    return this.http.post<Paged<UserSearch>>(
      `${this.root}/search`,
      request,
      PageRequest.toQueryParams(pageRequest)
    );
  }

  getUserForEdition(id: number): Observable<UserSearch> {
    return this.http.get<UserSearch>(`${this.root}/edit/${id}`);
  }

  checkPhoneForEdition(phone: string): Observable<UserSearch[]> {
    return this.http.post<UserSearch[]>(`${this.root}/edit/check-phone`, {
      content: phone
    });
  }

  editUser(id: number, user: UserSearch): Observable<UserSearch> {
    return this.http.put<UserSearch>(`${this.root}/edit/${id}`, user);
  }
}
