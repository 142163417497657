import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {TreeItemNode} from '../model/event/treeData/tree-item-node.model';
import {TreeItemFlatNode} from '../model/event/treeData/tree-item-flat-node.model';

export enum TreeContentType {
  PLACE,
  SESSION,
  ENSEMBLE,
  SCHOOL,
  NONE
}

@Injectable({
  providedIn: 'root'
})
export class TreeDataService {
  currentPage: string;
  currentContentType: TreeContentType; // 0 == place, 1 == session

  // place
  private readonly dataAllPlace: BehaviorSubject<TreeItemNode[]> = new BehaviorSubject<
    TreeItemNode[]
  >([]);
  public allPlacesMessager = this.dataAllPlace.asObservable();

  private readonly dataAllExcludePlace: BehaviorSubject<TreeItemNode[]> = new BehaviorSubject([]);
  public allExcludePlacesMessager = this.dataAllExcludePlace.asObservable();

  private readonly dataChoixPlaceListActivite: BehaviorSubject<TreeItemFlatNode[]> =
    new BehaviorSubject([]);
  public choixPlacesMessagerListActivite = this.dataChoixPlaceListActivite.asObservable();

  private readonly dataChoixPlaceListLdlvActivite: BehaviorSubject<TreeItemFlatNode[]> =
    new BehaviorSubject([]);
  public choixPlacesMessagerListLdlvActivite = this.dataChoixPlaceListLdlvActivite.asObservable();

  private readonly dataChoixPlaceListJobActivite: BehaviorSubject<TreeItemFlatNode[]> =
    new BehaviorSubject([]);
  public choixPlacesMessagerListJobActivite = this.dataChoixPlaceListJobActivite.asObservable();

  private readonly dataChoixPlaceDashboard: BehaviorSubject<TreeItemFlatNode[]> =
    new BehaviorSubject([]);
  public choixPlacesMessagerDashboard = this.dataChoixPlaceDashboard.asObservable();

  private readonly dataChoixPlaceListJeune: BehaviorSubject<TreeItemFlatNode[]> =
    new BehaviorSubject([]);
  public choixPlacesMessagerListJeune = this.dataChoixPlaceListJeune.asObservable();

  private readonly dataChoixPlaceProfileYoung: BehaviorSubject<TreeItemFlatNode[]> =
    new BehaviorSubject([]);
  public choixPlacesMessagerProfileYoung = this.dataChoixPlaceProfileYoung.asObservable();

  private readonly dataChoixPlaceJobProfileYoung: BehaviorSubject<TreeItemFlatNode[]> =
    new BehaviorSubject([]);
  public choixPlacesMessagerJobProfileYoung = this.dataChoixPlaceJobProfileYoung.asObservable();

  private readonly dataChoixPlaceCreationEvent: BehaviorSubject<TreeItemFlatNode[]> =
    new BehaviorSubject([]);
  public choixPlacesMessagerCreationEvent = this.dataChoixPlaceCreationEvent.asObservable();

  private readonly dataChoixPlaceIntervenant: BehaviorSubject<TreeItemFlatNode[]> =
    new BehaviorSubject([]);
  public choixPlacesMessagerIntervenant = this.dataChoixPlaceIntervenant.asObservable();

  /** Reporting **/
  private readonly dataChoixPlaceReportingSuivi: BehaviorSubject<TreeItemFlatNode[]> =
    new BehaviorSubject([]);
  public choixPlacesMessagerReportingSuivi = this.dataChoixPlaceReportingSuivi.asObservable();

  private readonly dataChoixPlaceReportingReinscription: BehaviorSubject<TreeItemFlatNode[]> =
    new BehaviorSubject([]);
  public choixPlacesMessagerReportingReinscription =
    this.dataChoixPlaceReportingReinscription.asObservable();

  private readonly dataChoixPlaceReportingOther: BehaviorSubject<TreeItemFlatNode[]> =
    new BehaviorSubject([]);
  public choixPlacesMessagerReportingOther = this.dataChoixPlaceReportingOther.asObservable();

  // session
  private readonly dataAllSession: BehaviorSubject<TreeItemNode[]> = new BehaviorSubject([]);
  public allSessionMessager = this.dataAllSession.asObservable();
  // project
  private readonly dataAllEnsemble: BehaviorSubject<TreeItemNode[]> = new BehaviorSubject([]);
  public allEnsembleMessager = this.dataAllEnsemble.asObservable();

  // project
  private readonly dataAllEnsembleAttribution: BehaviorSubject<TreeItemNode[]> =
    new BehaviorSubject([]);
  public allEnsembleMessagerAttribution = this.dataAllEnsembleAttribution.asObservable();

  // schoolLevel
  private readonly dataAllSchool: BehaviorSubject<TreeItemNode[]> = new BehaviorSubject([]);
  public allSchoolLevel = this.dataAllSchool.asObservable();

  private readonly dataChoixEnsembleList: BehaviorSubject<TreeItemFlatNode[]> = new BehaviorSubject(
    []
  );
  public choixEnsembleMessagerList = this.dataChoixEnsembleList.asObservable();

  private readonly dataChoixSessionListActivite: BehaviorSubject<TreeItemFlatNode[]> =
    new BehaviorSubject([]);
  public choixSessionMessagerListActivite = this.dataChoixSessionListActivite.asObservable();

  private readonly dataChoixSessionEventCreation: BehaviorSubject<TreeItemFlatNode[]> =
    new BehaviorSubject([]);
  public choixSessionMessagerEventCreation = this.dataChoixSessionEventCreation.asObservable();

  private readonly dataChoixSessionListJeune: BehaviorSubject<TreeItemFlatNode[]> =
    new BehaviorSubject([]);
  public choixSessionMessagerListJeune = this.dataChoixSessionListJeune.asObservable();

  private readonly dataChoixSchoolListJeune: BehaviorSubject<TreeItemFlatNode[]> =
    new BehaviorSubject([]);
  public choixSchoolMessagerListJeune = this.dataChoixSchoolListJeune.asObservable();

  // place
  changeAllPlace(placeList: TreeItemNode[]) {
    this.dataAllPlace.next(placeList);
  }

  addExcludePlace(place: TreeItemNode) {
    const currentList = this.dataAllExcludePlace.getValue();
    currentList.push(place);

    this.dataAllExcludePlace.next(currentList);
  }

  removeExcludePlace(placeId: number) {
    const currentList = this.dataAllExcludePlace.getValue();
    const search = currentList.find((item) => item.id === placeId);
    const index = currentList.indexOf(search);
    currentList.splice(index, 1);

    this.dataAllExcludePlace.next(currentList);
  }

  resetAllExcludePlace() {
    this.dataAllExcludePlace.next([]);
  }

  changeChoixPlace(placeList: TreeItemFlatNode[]) {
    const methodByPageMap = {
      Dashboard: () => this.changeChoixPlaceDashboard(placeList),
      ListActivite: () => this.changeChoixPlaceListActivite(placeList),
      ProfileYoung: () => this.changeChoixPlaceProfileYoung(placeList),
      CreationEvent: () => this.changeChoixPlaceCreationEvent(placeList),
      ReportingSuivi: () => this.changeChoixPlaceReportingSuivi(placeList),
      ReportingReinscription: () => this.changeChoixPlaceReportingReinscription(placeList),
      ReportingOther: () => this.changeChoixPlaceReportingOther(placeList),
      ProfileYoungJob: () => this.changeChoixPlaceJob(placeList),
      ProfileIntervenantJob: () => this.changeChoixPlaceJob(placeList),
      CreateIntervenant: () => this.changeChoixPlaceIntervenant(placeList),
      EventSport: () => this.changeChoixPlaceListActivite(placeList),
      EventLDLV: () => this.changeChoixPlaceListLdlvActivite(placeList),
      EventJob: () => this.changeChoixPlaceListJobActivite(placeList),
      ListJeune: () => this.changeChoixPlaceListJeune(placeList)
    };

    const method = methodByPageMap[this.currentPage];
    if (method) {
      method();
    }
  }

  changeChoixPlaceListActivite(placeList: TreeItemFlatNode[]) {
    this.dataChoixPlaceListActivite.next(placeList);
  }

  changeChoixPlaceListLdlvActivite(placeList: TreeItemFlatNode[]) {
    this.dataChoixPlaceListLdlvActivite.next(placeList);
  }

  changeChoixPlaceListJobActivite(placeList: TreeItemFlatNode[]) {
    this.dataChoixPlaceListJobActivite.next(placeList);
  }

  changeChoixPlaceDashboard(placeList: TreeItemFlatNode[]) {
    this.dataChoixPlaceDashboard.next(placeList);
  }

  changeChoixPlaceListJeune(placeList: TreeItemFlatNode[]) {
    this.dataChoixPlaceListJeune.next(placeList);
  }

  changeChoixPlaceProfileYoung(placeList: TreeItemFlatNode[]) {
    this.dataChoixPlaceProfileYoung.next(placeList);
  }

  changeChoixPlaceCreationEvent(placeList: TreeItemFlatNode[]) {
    this.dataChoixPlaceCreationEvent.next(placeList);
  }

  changeChoixPlaceProfileJobYoung(placeList: TreeItemFlatNode[]) {
    this.dataChoixPlaceJobProfileYoung.next(placeList);
  }

  changeChoixPlaceIntervenant(placeList: TreeItemFlatNode[]) {
    this.dataChoixPlaceIntervenant.next(placeList);
  }

  /** Reporting **/

  changeChoixPlaceReportingSuivi(placeList: TreeItemFlatNode[]) {
    this.dataChoixPlaceReportingSuivi.next(placeList);
  }

  changeChoixPlaceReportingReinscription(placeList: TreeItemFlatNode[]) {
    this.dataChoixPlaceReportingReinscription.next(placeList);
  }

  changeChoixPlaceReportingOther(placeList: TreeItemFlatNode[]) {
    this.dataChoixPlaceReportingOther.next(placeList);
  }

  changeChoixPlaceJob(placeList: TreeItemFlatNode[]) {
    this.dataChoixPlaceJobProfileYoung.next(placeList);
  }

  // session
  changeAllSession(sessionList: TreeItemNode[]) {
    this.dataAllSession.next(sessionList);
  }

  changeChoixSessionEventCreation(sessionList: TreeItemFlatNode[]) {
    this.dataChoixSessionEventCreation.next(sessionList);
  }

  changeChoixSessionListActivite(sessionList: TreeItemFlatNode[]) {
    this.dataChoixSessionListActivite.next(sessionList);
  }

  changeChoixSessionListJeune(sessionList: TreeItemFlatNode[]) {
    this.dataChoixSessionListJeune.next(sessionList);
  }

  changeChoixSchoolListJeune(sessionList: TreeItemFlatNode[]) {
    this.dataChoixSessionListJeune.next(sessionList);
  }

  // school
  changeAllSchool(schoolList: TreeItemNode[]) {
    this.dataAllSchool.next(schoolList);
  }

  changeChoixSchool(schoolList: TreeItemFlatNode[]) {
    this.dataChoixSchoolListJeune.next(schoolList);
  }

  changeAllEnsemble(projectList: TreeItemNode[]) {
    this.dataAllEnsemble.next(projectList);
  }

  changeAllEnsembleAttribution(ensembleList: TreeItemNode[]) {
    this.dataAllEnsembleAttribution.next(ensembleList);
  }

  changeChoixEnsemble(ensembleList: TreeItemFlatNode[]) {
    this.dataChoixEnsembleList.next(ensembleList);
  }

  reset() {
    this.currentPage = undefined;
    this.currentContentType = undefined;
    this.changeAllPlace([]);
    this.changeChoixPlaceListActivite([]);
    this.changeChoixPlaceDashboard([]);
    this.changeAllSession([]);
    this.changeChoixSessionEventCreation([]);
    this.changeChoixSessionListActivite([]);
    this.changeChoixPlaceListJeune([]);
    this.changeChoixPlaceCreationEvent([]);
    this.changeChoixPlaceReportingSuivi([]);
    this.changeChoixPlaceReportingReinscription([]);
    this.changeChoixPlaceReportingOther([]);
    this.changeAllSchool([]);
    this.changeChoixPlaceProfileJobYoung([]);
    this.changeAllEnsemble([]);
    this.changeChoixEnsemble([]);
    this.changeAllEnsembleAttribution([]);
  }
}
