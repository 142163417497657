import {InputTextComponent} from './form-parts/form-items/input-text/input-text.component';
import {SelectComponent} from './form-parts/form-items/select/select.component';
import {SwitchComponent} from './form-parts/form-items/switch/switch.component';
import {RadioButtonComponent} from './form-parts/form-items/radio-button/radio-button.component';
import {InputNumberComponent} from './form-parts/form-items/input-number/input-number.component';
import {SelectNumberComponent} from './form-parts/form-items/select-number/select-number.component';
import {SelectTreeComponent} from './form-parts/form-items/select-tree/select-tree.component';
import {SelectMultipleComponent} from './form-parts/form-items/select-multiple/select-multiple.component';
import {SelectMultipleTreeComponent} from './form-parts/form-items/select-multiple-tree/select-multiple-tree.component';
import {SelectMultipleTreeInputComponent} from './form-parts/form-items/select-multiple-tree-input/select-multiple-tree-input.component';
import {DataListComponent} from './form-parts/form-items/data-list/data-list.component';
import {TreeSettingComponent} from './form-parts/form-items/tree-setting/tree-setting.component';
import {DialogAddRelationSpeakerComponent} from './dialog/dialog-add-relation-speaker/dialog-add-relation-speaker.component';
import {PaginationComponent} from './pagination/pagination.component';
import {InputTextGoogleAutocompleteComponent} from './form-parts/form-items/input-text-google-autocomplete/input-text-google-autocomplete.component';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';

import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTreeModule} from '@angular/material/tree';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatNativeDateModule} from '@angular/material/core';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatRadioModule} from '@angular/material/radio';
import {MatStepperModule} from '@angular/material/stepper';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFileInputModule} from './form-parts/mat-file-input/mat-file-input.module';
import {CalendarModule} from 'primeng/calendar';
import {DragDropDirective} from './directives/drag-drop.directive';
import {HasModuleDirective} from './directives/has-module.directive';
import {NgModule} from '@angular/core';
import {ProgramNamePipe} from './pipes/program-name.pipe';
import {FullnamePipe} from './pipes/fullname.pipe';
import {SortPipe} from './pipes/sort.pipe';
import {ReferentielPipe} from './pipes/referentiel.pipe';

const FORM_MODULE = [
  InputTextComponent,
  SelectComponent,
  SwitchComponent,
  RadioButtonComponent,
  InputNumberComponent,
  SelectNumberComponent,
  SelectTreeComponent,
  SelectMultipleComponent,
  SelectMultipleTreeComponent,
  SelectMultipleTreeInputComponent,
  DataListComponent,
  TreeSettingComponent,
  DialogAddRelationSpeakerComponent,
  PaginationComponent,
  InputTextGoogleAutocompleteComponent
];

const BASE_MODULES = [CommonModule, FormsModule, HttpClientModule, ReactiveFormsModule];

const MATERIAL_MODULE = [
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatToolbarModule,
  MatListModule,
  MatButtonModule,
  MatSelectModule,
  MatDatepickerModule,
  MatIconModule,
  MatMenuModule,
  DragDropModule,
  MatProgressSpinnerModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatTreeModule,
  MatCheckboxModule,
  MatSidenavModule,
  MatNativeDateModule,
  MatTableModule,
  MatTabsModule,
  MatButtonToggleModule,
  MatExpansionModule,
  MatTooltipModule,
  MatRadioModule,
  MatStepperModule,
  MatProgressBarModule,
  MatSlideToggleModule,
  MatPaginatorModule,
  MatDialogModule,
  MatFileInputModule
];

const PRIMENG_MODULE = [CalendarModule];

const DIRECTIVES = [DragDropDirective, HasModuleDirective];

const PIPES = [FullnamePipe, SortPipe, ReferentielPipe, ProgramNamePipe];

@NgModule({
  exports: [
    ...BASE_MODULES,
    ...MATERIAL_MODULE,
    ...PRIMENG_MODULE,
    ...FORM_MODULE,
    ...DIRECTIVES,
    ...PIPES
  ],
  imports: [
    ...BASE_MODULES,
    ...MATERIAL_MODULE,
    ...PRIMENG_MODULE,
    ...FORM_MODULE,
    ...DIRECTIVES,
    ...PIPES
  ]
})
export class SharedModule {}
