export enum TypeContactBinomeEnum {
  PRESENTIEL,
  DIGITAL
}

export namespace TypeContactBinomeEnum {
  export function format(typeContact: number) {
    switch (TypeContactBinomeEnum[typeContact]) {
      case 'PRESENTIEL':
        return 'Présentiel';
      case 'DIGITAL':
        return 'Digital';
      default:
        return '';
    }
  }
}
