export enum TypeBinomeEnum {
  PARRAIN = 0,
  TUTEUR_SCOLAIRE,
  MENTOR_ANGLAIS,
  REFERENT_EXTERNE
}

export namespace TypeBinomeEnum {
  export function format(typeBinome: number) {
    switch (TypeBinomeEnum[typeBinome]) {
      case 'PARRAIN':
        return 'Parrain';
      case 'TUTEUR_SCOLAIRE':
        return 'Tuteur scolaire';
      case 'MENTOR_ANGLAIS':
        return 'Mentor anglais';
      case 'REFERENT_EXTERNE':
        return 'Référent externe';
      default:
        return '';
    }
  }
}
