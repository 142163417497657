import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgClass, NgIf} from '@angular/common';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule, NgClass]
})
export class SwitchComponent {
  @Input() group; // Group contenant le FormControle
  @Input() fcName: string; // Nom du FormControl

  @Input() textLabel: string; // Label when switch not checked or always if no textLabelChecked
  @Input() textLabelChecked: string; // Label when switch checked

  @Input() i = 0; // i si l'input est dans une boucle (pour éviter d'avoir 2 composants ayant le même id

  @Input() modelAttribute: boolean; // Valeur entrée
  @Output() modelAttributeChange = new EventEmitter(); // Valeur envoyée au parent

  @Input() disabled;
  @Input() required = false;

  get label(): string {
    return this.modelAttribute
      ? this.textLabelChecked
        ? this.textLabelChecked
        : this.textLabel
      : this.textLabel;
  }

  change(newValue: boolean) {
    this.modelAttribute = newValue;
    this.modelAttributeChange.emit(newValue);
  }

  changeEvent(event) {
    this.change(event.target.checked);
  }
}
