import {Event} from './event.model';
import {SessionTypeEnum} from '../enums/session-type.enum';

export class Session {
  id: number;
  name: string;
  minAgeGroup: number;
  maxAgeGroup: number;
  minAgeSubGroup: number;
  maxAgeSubGroup: number;
  isActive: boolean;
  sessionType: SessionTypeEnum;

  listOfEvent: Event[];
}
