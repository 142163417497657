import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {AlertService} from 'src/app/services/alert.service';
import {Place} from 'src/app/model/event/place/place.model';
import {PlaceWebservice} from 'src/app/services/webservices/place.webservice';
import {Destroyed} from '../../directives/destroyed.directive';
import {AlertComponent} from '../../alert/alert/alert.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {InputTextComponent} from '../../form-parts/form-items/input-text/input-text.component';

@Component({
  selector: 'app-dialog-add-place',
  templateUrl: './dialog-add-place.component.html',
  styleUrls: ['./dialog-add-place.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, InputTextComponent, MatCheckboxModule, AlertComponent]
})
export class DialogAddPlaceComponent extends Destroyed implements OnInit, OnDestroy {
  @Input() id: number;
  result = false;
  formGroup: UntypedFormGroup;
  returnPlace: Place;

  constructor(
    public dialogRef: MatDialogRef<DialogAddPlaceComponent>,
    private readonly fb: UntypedFormBuilder,
    private readonly alertService: AlertService,
    private readonly placeWebservice: PlaceWebservice
  ) {
    super();
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      name: [null, Validators.required],
      code: [null, [Validators.pattern('^[0-9]+(?:([-][0-9]+)+)?$')]],
      isActive: null
    });
    if (this.id && this.id > 0) {
      this.placeWebservice
        .getPlaceById(this.id)
        .pipe(this.untilDestroyed())
        .subscribe((data: Place) => {
          this.returnPlace = data;
          this.formGroup.controls.name.setValue(this.returnPlace.nameCenter);
          this.formGroup.controls.code.setValue(this.returnPlace.proximity);
          this.formGroup.controls.isActive.setValue(this.returnPlace.isActif);
        });
    } else {
      this.returnPlace = new Place();
    }
  }

  yes() {
    if (this.formGroup.valid) {
      this.result = true;

      const val = this.formGroup.value;

      this.returnPlace.nameCenter = val.name;
      this.returnPlace.proximity = val.code;
      this.returnPlace.isActif = val.isActive;

      this.dialogRef.close(this.returnPlace);
    } else {
      this.alertService.clear();
      this.alertService.error('Merci de saisir un nom et un code avant de valider');
    }
  }

  no() {
    this.result = false;
    this.dialogRef.close(null);
  }

  ngOnDestroy() {
    this.alertService.clear();
    if (this.result === false) {
      this.dialogRef.close(null);
    }
  }
}
