import {Pipe, PipeTransform} from '@angular/core';
import {User} from '../../../model/user/user.model';

@Pipe({
  name: 'fullname',
  standalone: true
})
export class FullnamePipe implements PipeTransform {
  transform(user?: User): string {
    if (user) {
      return `${user.firstName} ${user.lastName.toUpperCase()}`;
    }
    return '';
  }
}
